const regex = new RegExp("[0-9]");

export default function verifyPhone(phone: string) {
  const number = phone
    .split("")
    .filter((item) => regex.test(item))
    .join("");

  if (number.length !== 11 || number[2] !== "9") {
    return false;
  }

  return true;
}
