import ShopType from "../../types/Shop";
import "./styles.css"

export interface SelectShopObjectType {
  shop: ShopType;
  total: number;
}

interface ListItemsCustomProps {
  objectProp: SelectShopObjectType;
  onSelectShop: (shop: string) => void
}

export function ListItemsCustom({ objectProp, onSelectShop }: ListItemsCustomProps) {
  return (
    <div className="Container" onClick={() => onSelectShop(objectProp.shop.id)} >
      <div className="item" >
        <p className="title">{objectProp.shop.id}</p>
        <p className="name">{objectProp.shop.name}</p>
        <p className="total">{`Total: ${objectProp.total}`}</p>
      </div>
    </div>
  );
}