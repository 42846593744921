import { useState } from "react";
import Select from "react-select";

import "./styles.css";

interface FormSelectProps {
  label: string;
  required?: boolean;
  options: { value: string; label: string }[];
  value: string;
  className?: string;
  menuPlacement?: "top" | "auto" | "bottom";
  placeholder?: string;
  onChange: (value: { value: string; label: string } | null) => void;
  onFocus?: () => void;
}

export default function FormSelect({
  label,
  required,
  value,
  options,
  className,
  placeholder,
  onFocus,
  ...rest
}: FormSelectProps) {
  const emptyArray = [] as { value: string; label: string }[];

  const [inputValue, setInputValue] = useState("");

  return (
    <div className="box-select">
      <label className="form-select-label">
        {label}
        {required && <strong className="input-required">*</strong>}
      </label>
      <Select
        className={`form-select-component ${className}`}
        options={
          required
            ? options
            : emptyArray.concat({ value: "", label: "-" }, options)
        }
        {...rest}
        inputValue={inputValue}
        isSearchable={false}
        defaultInputValue={""}
        onInputChange={(newValue) => setInputValue(newValue.replace(",", "."))}
        autoFocus={false}
        value={options.find((item) => item.value === value) ?? null}
        placeholder={placeholder ?? "Escolha"}
        onFocus={onFocus}
        maxMenuHeight={200}
      />
    </div>
  );
}
