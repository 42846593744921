import removerCharsCpf from "./removeCharsCpf";

export default function formatCpf(cpf: string) {
    var cpfFormated = removerCharsCpf(cpf);

    if(cpfFormated.length > 3) {
        cpfFormated = cpfFormated.substr(0, 3) + "." + cpfFormated.substr(3, cpf.length);
    }
    if(cpfFormated.length > 7) {
        cpfFormated = cpfFormated.substr(0, 7) + "." + cpfFormated.substr(7, cpf.length);
    } 
    if(cpfFormated.length > 11) {
        cpfFormated = cpfFormated.substr(0, 11) + "-" + cpfFormated.substr(11, cpf.length);
    }

    return cpfFormated;
}