export default function verifyCPF(cpf: string) {
  var cpfList = cpf.split("") as string[];

  var v1 = 0;
  var v2 = 0;
  var aux = false;

  for (var i = 1; cpfList.length > i; i++) {
    if (cpfList[i - 1] !== cpfList[i]) {
      aux = true;
    }
  }

  if (aux === false) {
    return false;
  }
  // eslint-disable-next-line
  for (var i = 0, p = 10; cpfList.length - 2 > i; i++, p--) {
    v1 += Number(cpfList[i]) * p;
  }

  v1 = (v1 * 10) % 11;

  if (v1 === 10) {
    v1 = 0;
  }

  if (v1 !== Number(cpfList[9])) {
    return false;
  }

  // eslint-disable-next-line
  for (var i = 0, p = 11; cpfList.length - 1 > i; i++, p--) {
    v2 += Number(cpfList[i]) * p;
  }

  v2 = (v2 * 10) % 11;

  if (v2 === 10) {
    v2 = 0;
  }

  if (v2 !== Number(cpfList[10])) {
    return false;
  } else {
    return true;
  }
}
