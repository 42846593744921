import { MdSearch } from "react-icons/md";
import ReactInputMask from "react-input-mask";

import "./styles.css";

interface SearchInputProps {
  value: string | number;
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  mask?: string;
}

export default function SearchInput({
  value,
  setValue,
  placeholder,
  mask,
}: SearchInputProps) {
  return (
    <div className="search-input-component">
      <ReactInputMask
        mask={mask ?? ""}
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={setValue}
      />

      <button className="search-button" type="submit" title="Pesquisar">
        <MdSearch />
      </button>
    </div>
  );
}
