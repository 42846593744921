import "./styles.css"

interface PaginationProps {
  number: number
}

export default function Pagination({ number }: PaginationProps) {
  return (
    <div className="pagination-component">
      <p>{number}</p>
    </div>
  )
}
