import "./styles.css";

interface LoadingProps {
  size?: number;
  transparency?: boolean;
}

export default function Loading({ size, transparency }: LoadingProps) {
  return (
    <div
      className="loading-component"
      style={{
        width: size ?? "100%",
        height: size ?? "100%",
        background: transparency ? "transparent" : "#ffffff90",
      }}
    >
      <div
        className="loading-circle"
        style={{ borderWidth: size ? size / 10 : 5 }}
      />
    </div>
  );
}
