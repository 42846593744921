import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import { LensContext } from "../../../contexts/lensContext";
import FormSelect from "../../../components/FormSelect";
import PageModel from "../../../components/PageModel";
import ContentQuestionary from "../../../components/ContentQuestionary";
import ModalInformation from "../../../components/ModalInformation";

import questionaryImage from "../../../assets/prescription.jpg";

import "./styles.css";
import ModalPresentation from "../../../components/ModalPresentation";

export default function PrescriptionPage() {
  const navigate = useNavigate();

  const {
    prescription,
    setPrescription,
    frames,
    setFrames,
    useGlasses,
    setUseGlasses,
    glassesSelected,
    setGlassesSelected,
  } = useContext(QuestionaryContext);

  const { designs } = useContext(LensContext);

  const [isShowModalPresentation, setIsShowModalPresentation] = useState(true);

  const [arrayEsf, setArrayEsf] = useState<string[]>([]);
  const [arrayCil, setArrayCil] = useState<string[]>([]);
  const [arrayEixo, setArrayEixo] = useState<string[]>([]);
  const [arrayAdition, setArrayAdition] = useState<string[]>([]);

  const [inputError, setInputError] = useState(false);

  const [esfRError, setEsfRError] = useState(false);
  const [esfLError, setEsfLError] = useState(false);
  const [cilRError, setCilRError] = useState(false);
  const [cilLError, setCilLError] = useState(false);
  const [eixoRError, setEixoRError] = useState(false);
  const [eixoLError, setEixoLError] = useState(false);
  const [framesError, setFramesError] = useState(false);
  const [useGlassesError, setUseGlassesError] = useState(false);
  const [glassesSelectedError, setGlassesSelectedError] = useState(false);

  useEffect(() => {
    insertValuesInputs();

    sessionStorage.setItem("show-slowly", "yes");

    //mostrar modal na proxima tela
    sessionStorage.setItem("modal-already-showed", "no");
  }, []);

  useEffect(() => {
    if (prescription.adition === "") {
      setUseGlasses("não");
    }
  }, [prescription.adition]);

  useEffect(() => {
    if (useGlasses === "" || useGlasses === "não") {
      setGlassesSelected("");
    }
  }, [useGlasses]);

  useEffect(() => {
    if (
      cilLError ||
      cilRError ||
      eixoLError ||
      eixoRError ||
      framesError ||
      useGlassesError ||
      glassesSelectedError
    ) {
      setInputError(true);
      setTimeout(() => setInputError(false), 2500);
    }
  }, [
    cilLError,
    cilRError,
    eixoLError,
    eixoRError,
    framesError,
    useGlassesError,
    glassesSelectedError,
  ]);

  function insertValuesInputs() {
    const esf = [];
    const cil = [];
    const eixo = [];
    const adition = [];

    for (let i = -25; i <= 25; i += 0.25) {
      let signal = i > 0 ? "+" : "";
      let numberString = signal + String(i.toFixed(2));

      esf.push(numberString);
      if (i >= -15 && i < 0) cil.push(numberString);
      if (i >= 1 && i <= 4) adition.push(numberString);
    }

    for (let i = 0; i <= 180; i++) {
      eixo.push(String(i));
    }

    setArrayEsf(esf);
    setArrayCil(cil.reverse());
    setArrayEixo(eixo);
    setArrayAdition(adition);
  }

  function verifyInputs() {
    if (prescription.rightEye.esf === "") {
      setEsfRError(true);
      setTimeout(() => setEsfRError(false), 4000);
      return false;
    }

    if (prescription.rightEye.cil === "" && prescription.rightEye.eixo !== "") {
      setCilRError(true);
      setTimeout(() => setCilRError(false), 4000);
      return false;
    }

    if (prescription.rightEye.eixo === "" && prescription.rightEye.cil !== "") {
      setEixoRError(true);
      setTimeout(() => setEixoRError(false), 4000);
      return false;
    }

    if (prescription.leftEye.esf === "") {
      setEsfLError(true);
      setTimeout(() => setEsfLError(false), 4000);
      return false;
    }

    if (prescription.leftEye.cil === "" && prescription.leftEye.eixo !== "") {
      setCilLError(true);
      setTimeout(() => setCilLError(false), 4000);
      return false;
    }

    if (prescription.leftEye.eixo === "" && prescription.leftEye.cil !== "") {
      setEixoLError(true);
      setTimeout(() => setEixoLError(false), 4000);
      return false;
    }

    if (frames === "") {
      setFramesError(true);
      setTimeout(() => setFramesError(false), 4000);
      return false;
    }

    if (prescription.adition !== "" && useGlasses === "") {
      setUseGlassesError(true);
      setTimeout(() => setUseGlassesError(false), 4000);
      return false;
    }

    if (useGlasses === "sim" && glassesSelected === "") {
      setGlassesSelectedError(true);
      setTimeout(() => setGlassesSelectedError(false), 4000);
      return false;
    }

    return true;
  }

  function next() {
    if (!verifyInputs()) {
      return;
    }

    navigate("/questionary/prescription/resume");
  }

  function goBack() {
    navigate("/questionary");
  }

  return (
    <PageModel title="Receita atual" previous={goBack} next={next} number={1}>
      {inputError && (
        <ModalInformation
          text="Algo não está certo. Revise os dados"
          onClose={() => setInputError(false)}
        />
      )}

      {isShowModalPresentation && (
        <ModalPresentation onClose={() => setIsShowModalPresentation(false)} />
      )}

      <ContentQuestionary srcImage={questionaryImage}>
        <div className="question-box">
          <h5 className="question-subtitle">Olho Direito</h5>

          <div className="box-line-input">
            <FormSelect
              placeholder="-"
              required
              className={esfRError ? "input input-error" : "input"}
              value={prescription.rightEye.esf}
              options={arrayEsf.map((item) => {
                return { label: item, value: item };
              })}
              label="ESF"
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  rightEye: {
                    ...prescription.rightEye,
                    esf: value.value,
                  },
                })
              }
            />

            <FormSelect
              placeholder="-"
              className={cilRError ? "input input-error" : "input"}
              value={prescription.rightEye.cil}
              options={arrayCil.map((item) => {
                return { label: item, value: item };
              })}
              label="CIL"
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  rightEye: {
                    ...prescription.rightEye,
                    cil: value.value,
                  },
                })
              }
            />

            <FormSelect
              label="EIXO"
              placeholder="-"
              className={eixoRError ? "input input-error" : "input"}
              value={prescription.rightEye.eixo}
              options={arrayEixo.map((item) => {
                return { label: item, value: item };
              })}
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  rightEye: {
                    ...prescription.rightEye,
                    eixo: value.value,
                  },
                })
              }
            />
          </div>

          <h5 className="question-subtitle">Olho Esquerdo</h5>

          <div className="box-line-input">
            <FormSelect
              placeholder="-"
              required
              className={esfLError ? "input input-error" : "input"}
              value={prescription.leftEye.esf}
              options={arrayEsf.map((item) => {
                return { label: item, value: item };
              })}
              label="ESF"
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  leftEye: {
                    ...prescription.leftEye,
                    esf: value.value,
                  },
                })
              }
            />

            <FormSelect
              placeholder="-"
              className={cilLError ? "input input-error" : "input"}
              value={prescription.leftEye.cil}
              options={arrayCil.map((item) => {
                return { label: item, value: item };
              })}
              label="CIL"
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  leftEye: {
                    ...prescription.leftEye,
                    cil: value.value,
                  },
                })
              }
            />

            <FormSelect
              label="EIXO"
              placeholder="-"
              className={eixoLError ? "input input-error" : "input"}
              value={prescription.leftEye.eixo}
              options={arrayEixo.map((item) => {
                return { label: item, value: item };
              })}
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  leftEye: {
                    ...prescription.leftEye,
                    eixo: value.value,
                  },
                })
              }
            />
          </div>

          <div className="box-line-input">
            <FormSelect
              label="Adicão"
              placeholder="-"
              className="input"
              value={prescription.adition}
              options={arrayAdition.map((item) => {
                return { label: item, value: item };
              })}
              onChange={(value) =>
                value &&
                setPrescription({
                  ...prescription,
                  adition: value.value,
                })
              }
            />
          </div>

          <div className="box-line-input">
            <FormSelect
              className={framesError ? "input input-error" : "input"}
              options={["Nylon", "Aro total", "Balgrif"].map((item) => {
                return { label: item, value: item };
              })}
              label="Armação"
              value={frames as string}
              required
              onChange={(value) => value && setFrames(value.value)}
              menuPlacement="top"
              onFocus={() => setUseGlassesError(false)}
            />

            {Number(prescription.adition) > 0 && (
              <FormSelect
                className={useGlassesError ? "input input-error" : "input"}
                options={["sim", "não"].map((item) => {
                  return { label: item, value: item };
                })}
                label="Usa lentes multifocais?"
                required
                value={useGlasses}
                onChange={(value) => value && setUseGlasses(value.value)}
                menuPlacement="top"
              />
            )}
          </div>

          <div className="box-line-input">
            {useGlasses === "sim" && (
              <FormSelect
                className={glassesSelectedError ? "input input-error" : "input"}
                options={[{ value: "não sei", label: "Não sei" }].concat(
                  designs
                    .filter((item) => item.level >= 5)
                    .sort((a, b) => a.name.localeCompare(b.name)) // Ordena alfabeticamente pelo nome
                    .map((item) => {
                      return { label: item.name, value: item.id };
                    })
                )}
                label="Qual?"
                required
                value={glassesSelected}
                onChange={(value) => value && setGlassesSelected(value.value)}
                menuPlacement="top"
              />
            )}
          </div>
        </div>
      </ContentQuestionary>
    </PageModel>
  );
}
