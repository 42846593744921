import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import { AntireflectionType } from "../../../types/Categories";
import ScoreBar from "../../../components/ScoreBar";
import PageModel from "../../../components/PageModel";
import "../DesignPage";
import '../../admin/register/lenses';
import "../../../contexts/lensContext";
import '../RefractionPage';

import "./styles.css";

export default function AntireflectionPage() {
  const navigate = useNavigate();

  const {
    getAntireflectionOptions,
    switchedLens,
    setSwitchedLens,
    saveInStorage,
  } = useContext(QuestionaryContext);

  const [antireflection, setAntireflection] = useState<AntireflectionType[]>();
  const [emptyOption, setEmptyOption] = useState(false);

  const [optionSelectedId, setOptionSelectedId] = useState("");

  useEffect(() => {
    var options = getAntireflectionOptions();

    if (options.options.length === 0) {
      setAntireflection([]);
      return;
    }

    setEmptyOption(options.emptyOption);
    setAntireflection(
      options.options.map((item) => {
        return {
          ...item,
          type: "",
        };
      })
    );

    setOptionSelectedId(switchedLens.antireflection);
    // eslint-disable-next-line
  }, []);

  function switchAntireflection(option: string) {
    setOptionSelectedId(option);
  }

  function next() {
    saveInStorage(10);
    setSwitchedLens({
      ...switchedLens,
      antireflection: optionSelectedId,
    });
    navigate("/secondpair");
  }

  function notAR() {
    saveInStorage(10);
    setSwitchedLens({
      ...switchedLens,
      antireflection: "",
    });
    navigate("/secondpair");
  }

  function previous() {
    navigate("/technology");
    saveInStorage(8);
  }

  return (
    <PageModel
      title="Antirreflexo"
      previous={previous}
      next={next}
      number={8}
    >
      <div className="antireflection-content">
        {antireflection && antireflection.length > 0 ? (
          <>
            <div className="header-options">
              <p className="lens-name">LENTE</p>
              <p className="uv-protect">PROTEÇÃO UV</p>
              <p>PROTEÇÃO LUZ AZUL-VIOLETA</p>
              <p>EFICÁCIA DO ANTIRREFLEXO</p>
              <p>RESISTÊNCIA A ARANHÕES</p>
              <p>RESISTÊNCIA À POEIRA</p>
              <p>RESISTÊNCIA À ÁGUA</p>
              <p className="haze-resistence">RESISTÊNCIA AO EMBAÇAMENTO</p>
              <p className="digital-brand">MARCA DIGITAL</p>
              <p className="scarf">LENÇO</p>
              <p>COR RESIDUAL</p>
            </div>

            <div className="options">
              {antireflection &&
                antireflection
                  .sort((a, b) => (a.level && b.level ? b.level - a.level : 0))
                  .map((item, index) => (
                    <button
                      className={
                        optionSelectedId === item.id ? "option selected" : "option"
                      }
                      key={index}
                      onClick={() => switchAntireflection(item.id)}
                    >
                      <div className="box-lens" key={item.name}>
                        {item.logo ? (
                          <img src={item.logo} alt={item.name} />
                        ) : (
                          <h3>{item.name}</h3>
                        )}
                        <p className="description">{item.description}</p>
                      </div>
                      <p className="uv-protect">
                        {item.scores?.uvProtect ? "FPS 0 até " + item.scores?.uvProtect : "-"}
                      </p>
                      <p>
                        <ScoreBar score={item.scores?.blueProtect} />
                      </p>
                      <p>
                        <ScoreBar score={item.scores?.antireflection} />
                      </p>
                      <p>
                        <ScoreBar score={item.scores?.scratchResistence} />
                      </p>
                      <p>
                        <ScoreBar score={item.scores?.dustResistence} />
                      </p>
                      <p>
                        <ScoreBar score={item.scores?.waterResistence} />
                      </p>
                      <p className="haze-resistence">
                        <ScoreBar score={item.scores?.hazeResistence} />
                      </p>
                      <p className="digital-brand">
                        {item.scores?.digitalBrand ?? "-"}
                      </p>
                      <p className="scarf">{item.scores?.scarf ?? "-"}</p>
                      <p>
                        <span
                          className="residual-color"
                          style={{
                            background: item.scores?.residualColor ?? "-",
                          }}
                        >
                          {!item.scores?.residualColor && "-"}
                        </span>
                      </p>
                    </button>
                  ))}
            </div>

            {emptyOption && (
              <button onClick={() => notAR()} className="not-ar">
                Não quero
              </button>
            )}
          </>
        ) : (
          <strong>
            Não há tratamento antirreflexo para essa lente e índice de refração
          </strong>
        )}
      </div>
    </PageModel>
  );
}
