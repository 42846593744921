import { useContext, useEffect } from "react";
import { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { MyDocument } from "../../../components/DocumentPDF";
import Header from "../../../components/Header";
import { ResponseContext } from "../../../contexts/responseContext";
import { ResponseCompleteType } from "../../../types/Response";

import "./styles.css";

export default function ReportPDF() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { responses, getResponse } = useContext(ResponseContext);
  const [response, setResponse] = useState<ResponseCompleteType>();

  useEffect(() => {
    if(!id) return

    if (responses) {
      setResponse(responses.find((item) => item.id === id));
    } else {
      getResponse(id).then((res) => {
        setResponse(res);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="report-pdf-component">
      <Header>
        <div className="reports-header">
          <button onClick={() => navigate("/consultant/reports")}>
            <BiArrowBack />
          </button>
          <h1>Relatórios</h1>
        </div>
      </Header>
      <MyDocument response={response} />
    </div>
  );
}
