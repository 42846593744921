import { useContext } from "react";
import { BsCheck } from "react-icons/bs";
import { DesignAttributesContext } from "../../contexts/designAttributesContext";
import "./styles.css";

interface FormRadioCheckProps {
  options: string[];
  optionsSelected: string[];
  label: string;
  onToggle: (item: string) => void;
}

export default function FormRadioCheck({
  options,
  optionsSelected,
  label,
  onToggle,
}: FormRadioCheckProps) {
  const { designAttributes } = useContext(DesignAttributesContext);

  return (
    <div className="form-radio-check">
      <label>{label}</label>

      <div className="radio-options">
        {options.map((item) => (
          <div>
            <button
              type="button"
              className="button-radio"
              onClick={() => onToggle(item)}
            >
              {optionsSelected.includes(item) && <BsCheck />}
            </button>
            {designAttributes.find((att) => att.id === item)?.name}
          </div>
        ))}
      </div>
    </div>
  );
}
