import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import BoxError from "../../../../components/BoxError";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxSuccess from "../../../../components/BoxSuccess";
import { LensContext } from "../../../../contexts/lensContext";
import ListItem from "../../../../components/ListItems";
import { FabricatorType } from "../../../../types/Categories";
import FormSelect from "../../../../components/FormSelect";

import "./styles.css";

export default function RegisterFabricators() {
  const navigate = useNavigate();

  const { createFabricator, editFabricator, fabricators } =
    useContext(LensContext);

  const [name, setName] = useState("");
  const [available, setAvailable] = useState(true);

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSucess] = useState(false);

  const [itemEdit, setItemEdit] = useState<FabricatorType>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (fabricators.filter((item) => item.name === name).length > 0) {
      setInputError(["Fabricante já cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createFabricator(name, available)
      .then(() => {
        setRegisterSucess(true);
        setTimeout(() => setRegisterSucess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    const id = itemEdit.id;
    const name = itemEdit.name;
    const available = itemEdit.available;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      fabricators.filter((item) => item.name === name && item.id !== id)
        .length > 0
    ) {
      setInputError(["Fabricante já cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editFabricator(id, name, available)
      .then(() => {
        setRegisterSucess(true);
        setTimeout(() => setRegisterSucess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-fabricator-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar fabricante</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Fabricante criado com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
              disabled={inputError.length > 0}
            />

            <FormSelect
              label="Status"
              required
              value={available ? "available" : "not-available"}
              options={[
                { value: "available", label: "Habilitado" },
                { value: "not-available", label: "Desabilitado" },
              ]}
              onChange={(value) =>
                value && setAvailable(value.value === "available")
              }
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar fabricante</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Fabricante editado com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
              disabled={inputError.length > 0}
            />

            <FormSelect
              label="Status"
              required
              value={itemEdit.available ? "available" : "not-available"}
              options={[
                { value: "available", label: "Habilitado" },
                { value: "not-available", label: "Desabilitado" },
              ]}
              onChange={(value) =>
                value &&
                setItemEdit({
                  ...itemEdit,
                  available: value.value === "available",
                })
              }
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        <ListItem
          render={fabricators.map((item) => {
            return {
              item: [item.name, item.available ? "habilitado" : "desabilitado"],
              onEdit: () => {
                setItemEdit(item);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
