import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import BoxError from "../../../../components/BoxError";
import FormButton from "../../../../components/FormButton";
import FormSelect from "../../../../components/FormSelect";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import { LensContext } from "../../../../contexts/lensContext";
import BoxSuccess from "../../../../components/BoxSuccess";
import ListItem from "../../../../components/ListItems";

import "./styles.css";
import ModalDelete from "../../../../components/ModalDelete";

export default function RegisterBrands() {
  const navigate = useNavigate();

  const { fabricators, createBrand, editBrand, deleteBrand, brands } =
    useContext(LensContext);

  const [name, setName] = useState("");
  const [fabricatorSelected, setFabricatorSelected] = useState("");

  const [itemEdit, setItemEdit] =
    useState<{ id: string; name: string; fabricatorCod: string }>();

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [idToDelete, setIdToDelete] = useState<string>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (fabricatorSelected === "") {
      setInputError(["Tem que escolher um fabricante"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createBrand(name, fabricatorSelected)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    const id = itemEdit.id;
    const name = itemEdit.name;
    const fabricatorSelected = itemEdit.fabricatorCod;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (fabricatorSelected === "") {
      setInputError(["Tem que escolher um fabricante"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editBrand(id, name, fabricatorSelected)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => {
          setRegisterSuccess(false);
          setItemEdit(undefined);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onDelete(event: FormEvent) {
    event.preventDefault();

    if (!idToDelete) {
      return;
    }

    deleteBrand(idToDelete)
      .then(() => {
        setRegisterSuccess(true);
        setIdToDelete(undefined);
        setTimeout(() => {
          setRegisterSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-brand-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar marca</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`${name} criada com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormSelect
              label="Fabricante"
              required
              options={fabricators.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={fabricatorSelected}
              onChange={(value) => value && setFabricatorSelected(value.value)}
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar marca</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && <BoxSuccess message={`Editada com sucesso`} />}

            <FormInput
              label="Nome"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormSelect
              label="Fabricante"
              required
              value={itemEdit.fabricatorCod}
              options={fabricators.map((item) => {
                return { value: item.id, label: item.name };
              })}
              onChange={(value) =>
                value &&
                setItemEdit({ ...itemEdit, fabricatorCod: value.value })
              }
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        {idToDelete && (
          <ModalDelete
            nameItem={brands.find((item) => item.id === idToDelete)?.name ?? ""}
            onDelete={onDelete}
            onCancel={() => setIdToDelete(undefined)}
          />
        )}

        <ListItem
          render={brands.map((item) => {
            return {
              item: [
                fabricators.find(
                  (fabricator) => fabricator.id === item.fabricatorCod
                )?.name ?? "",
                item.name,
              ],
              onEdit: () => {
                setItemEdit(item);
              },
              onDelete: () => {
                setIdToDelete(item.id);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
