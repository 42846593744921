import React, { useState, useEffect } from "react";

import "./styles.css";

interface DatePickerType {
  day: string;
  month: string;
  year: string;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  dayInputError: boolean;
  monthInputError: boolean;
  yearInputError: boolean;
  setDayInputError: React.Dispatch<React.SetStateAction<boolean>>;
  setMonthInputError: React.Dispatch<React.SetStateAction<boolean>>;
  setYearInputError: React.Dispatch<React.SetStateAction<boolean>>;
}

const DatePicker: React.FC<DatePickerType> = ({
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
  dayInputError,
  setDayInputError,
  monthInputError,
  setMonthInputError,
  yearInputError,
  setYearInputError,
}) => {
  const [dayPicker, setDayPicker] = useState([""]);
  const [monthPicker, setMonthPicker] = useState([{ value: "", label: "" }]);
  const [yearPicker, setYearPicker] = useState([""]);

  useEffect(() => {
    let days = [],
      months = [],
      years = [];

    const monthsName = [
      "JAN",
      "FEV",
      "MAR",
      "ABR",
      "MAI",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OUT",
      "NOV",
      "DEZ",
    ];

    for (let i = 1; i < 32; i++) {
      days.push(String(i));
    }

    for (let j = 0; j < 12; j++) {
      months.push({ value: String(j), label: monthsName[j] });
    }

    const initialYear = 1900;
    const lastYear = new Date().getFullYear();

    for (let k = lastYear; k > initialYear; k--) {
      years.push(String(k));
    }

    setDayPicker(days);
    setMonthPicker(months);
    setYearPicker(years);
  }, []);

  return (
    <div className="grid-birth">
      <div className="day">
        <select
          value={day}
          className={dayInputError ? "input-error" : ""}
          onChange={(event) => setDay(event.target.value)}
          onFocus={() => setDayInputError && setDayInputError(false)}
        >
          <option value="0" hidden>
            Dia
          </option>
          {dayPicker.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>

      <div className="month">
        <select
          value={month}
          className={monthInputError ? "input-error" : ""}
          onChange={(event) => setMonth(event.target.value)}
          onFocus={() => setMonthInputError && setMonthInputError(false)}
        >
          <option value="-1" hidden>
            Mês
          </option>
          {monthPicker.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>

      <div className="year">
        <select
          value={year}
          className={yearInputError ? "input-error" : ""}
          onChange={(event) => setYear(event.target.value)}
          onFocus={() => setYearInputError && setYearInputError(false)}
        >
          <option value="0" hidden>
            Ano
          </option>
          {yearPicker.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default DatePicker;
