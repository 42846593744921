import "./styles.css";

interface ModalQuitQuestionaryProps {
  onQuit: () => void;
  onCancel: () => void;
}

export default function ModalQuitQuestionary({
  onCancel,
  onQuit,
}: ModalQuitQuestionaryProps) {
  return (
    <div className="modal-quit-questionary-component">
      <div className="modal-content">
        <h3 className="modal-title">Deseja realmente sair do questinário?</h3>

        <div className="modal-actions">
          <button onClick={onQuit}>Sim</button>
          <button onClick={onCancel}>Não</button>
        </div>
      </div>
    </div>
  );
}
