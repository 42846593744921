import "./styles.css";

interface ButtonRadioSelectProps {
  isSelected: boolean;
  setSelect: () => void;
  label: string;
}

export default function ButtonRadioSelect({
  label,
  isSelected,
  setSelect,
}: ButtonRadioSelectProps) {
  return (
    <button
      className={`${
        isSelected
          ? "button-radio-select-component selected"
          : "button-radio-select-component"
      }`}
      onClick={() => setSelect()}
    >
      {label}
    </button>
  );
}
