import ListItemsOptionType from "../types/ListItemsOption";

export default function listItemsFilter(
  option: ListItemsOptionType,
  filters: string[]
): boolean {
  let matchFilter = false;

  matchFilter = filters.every((filter, filterIndex) => {
    return option.item.some(
      (field, fieldIndex) =>
        (filterIndex === fieldIndex && field.includes(filter)) ||
        filter.trim() === ""
    );
  });

  return matchFilter;
}
