import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import { LensContext } from "../../../../contexts/lensContext";
import ListItem from "../../../../components/ListItems";

import "./styles.css";

export default function RegisterFamily() {
  const navigate = useNavigate();

  const { createFamily, editFamily, families } = useContext(LensContext);

  const [name, setName] = useState("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [itemEdit, setItemEdit] = useState<{ id: string; name: string }>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (families.filter((item) => item.name === name).length > 0) {
      setInputError(["Família já cadastrada"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createFamily(name)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    const id = itemEdit.id;
    const name = itemEdit.name;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (families.filter((item) => item.name === name).length > 0) {
      setInputError(["Família já cadastrada"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editFamily(id, name)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-family-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar família</h2>
            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Família cadastrada com sucesso`} />
            )}

            <FormInput
              label="Nome"
              type="number"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar família</h2>
            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Família editada com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        <ListItem
          render={families.map((item) => {
            return {
              item: [item.name],
              onEdit: () => {
                setItemEdit(item);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
