import { useContext } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { LoginContext } from "../../contexts/loginContext";

import "./styles.css";

export default function AdminHome() {
  //Variavel do react-router-dom para manipular as rotas
  const navigate = useNavigate();

  const { currentUser, logout } = useContext(LoginContext);

  function toConsultant() {
    navigate("/admin/consultant");
  }

  function toReports() {
    navigate("/admin/reports");
  }

  function toRegisterLens() {
    navigate("/admin/register/lens");
  }

  function toRegisterShop() {
    navigate("/admin/register/shop");
  }

  function toRegisterFabricator() {
    navigate("/admin/register/fabricator");
  }

  function toRegisterBrand() {
    navigate("/admin/register/brand");
  }

  function toRegisterDesign() {
    navigate("/admin/register/design");
  }

  function toRegisterDesignAttribute() {
    navigate("/admin/register/design-attributes");
  }

  function toRegisterFamily() {
    navigate("/admin/register/family");
  }

  function toRegisterRefractionIndex() {
    navigate("/admin/register/index");
  }

  function toRegisterAntireflection() {
    navigate("/admin/register/antireflection");
  }

  function toRegisterTechnology() {
    navigate("/admin/register/technology");
  }

  function toRegisterLevel() {
    navigate("/admin/register/level");
  }

  function toMonitoring() {
    navigate("/admin/register/monitoring");
  }

  function toattprices() {
    navigate("/admin/register/attprices");
  }
  //Fazer logout do consultor
  function onLogout() {
    logout();
    navigate("/login");
  }

  return (
    <div className="admin-home-page">
      <Header>
        <div className="actions-header">
          <strong>{currentUser?.name}</strong>

          <button
            className="logout-button"
            onClick={() => onLogout()}
            title="Sair"
          >
            <BiLogOut className="icon" size={30} />
          </button>
        </div>
      </Header>

      <main>
        <div className="grid-options">
          <button onClick={() => toConsultant()}>CONSULTOR</button>

          <button onClick={() => toReports()}>RELATÓRIOS</button>

          <button onClick={() => toRegisterShop()}>LOJAS</button>

          <button onClick={() => toRegisterLens()}>LENTES</button>

          <button onClick={() => toRegisterFabricator()}>FABRICANTES</button>

          <button onClick={() => toRegisterBrand()}>MARCAS</button>

          <button onClick={() => toRegisterDesign()}>DESENHOS</button>

          <button onClick={() => toRegisterDesignAttribute()}>
            ATRIBUTOS DE DESENHOS
          </button>

          <button onClick={() => toRegisterFamily()}>FAMÍLIAS</button>

          <button onClick={() => toRegisterRefractionIndex()}>
            ÍNDICES DE REFRAÇÃO
          </button>

          <button onClick={() => toRegisterAntireflection()}>
            TRATAMENTOS ANTIRREFLEXO
          </button>

          <button onClick={() => toRegisterTechnology()}>TECNOLOGIAS</button>

          <button onClick={() => toRegisterLevel()}>NÍVEIS</button>
          <button onClick={() => toMonitoring() }>MONITORAMENTO</button>
          <button onClick={() => toattprices() }>ATUALIZAR PREÇOS</button>
        </div>
      </main>
    </div>
  );
}
