import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ContentQuestionary from "../../../components/ContentQuestionary";
import PageModel from "../../../components/PageModel";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import ButtonRadioSelect from "../../../components/ButtonRadioSelect";

import questionaryImage from "../../../assets/light.jpg";

import "./styles.css";

export default function SunLightPage() {
  const navigate = useNavigate();

  const { outdoorActivity, setOutdoorActivity, photophobia, setPhotophobia } =
    useContext(QuestionaryContext);

  const [validationQ9, setValidationQ9] = useState(true);
  const [validationQ10, setValidationQ10] = useState(true);
  const [count, setCount] = useState(0);

  function next() {
    if (outdoorActivity === -1 || photophobia === -1) {
      setValidationQ9(outdoorActivity !== -1);
      setValidationQ10(photophobia !== -1);

      setTimeout(() => {
        setValidationQ9(true);
        setValidationQ10(true);
      }, 1500);
      return;
    }

    navigate("/design");
  }

  function previous() {
    navigate("/questionary/prescription/resume");
  }

  return (
    <PageModel
      title="Uso da visão nas rotinas diárias"
      previous={previous}
      next={next}
    >
      <>
        {count >= 2 ? (
          next()
        ) : (
          <ContentQuestionary srcImage={questionaryImage}>
            <div className="question-box">
              <h4>
                <strong className={validationQ9 ? "" : "error"}>
                  Você costuma fazer atividades ao ar livre?
                </strong>
              </h4>

              <div className="options">
                <ButtonRadioSelect
                  label="Nunca"
                  isSelected={outdoorActivity === 0}
                  setSelect={() => {
                    setOutdoorActivity(0);
                    setCount(count + 1);
                  }}
                />
                <ButtonRadioSelect
                  label="Às vezes"
                  isSelected={outdoorActivity === 1}
                  setSelect={() => {
                    setOutdoorActivity(1);
                    setCount(count + 1);
                  }}
                />
                <ButtonRadioSelect
                  label="Regularmente"
                  isSelected={outdoorActivity === 2}
                  setSelect={() => {
                    setOutdoorActivity(2);
                    setCount(count + 1);
                  }}
                />
              </div>
            </div>

            <div className="question-box">
              <h4>
                <strong className={validationQ10 ? "" : "error"}>
                  Você se sente incomodado com a luz ou brilho?
                </strong>
              </h4>

              <div className="options">
                <ButtonRadioSelect
                  label="Nunca"
                  isSelected={photophobia === 0}
                  setSelect={() => {
                    setPhotophobia(0);
                    setCount(count + 1);
                  }}
                />
                <ButtonRadioSelect
                  label="Às vezes"
                  isSelected={photophobia === 1}
                  setSelect={() => {
                    setPhotophobia(1);
                    setCount(count + 1);
                  }}
                />
                <ButtonRadioSelect
                  label="Regularmente"
                  isSelected={photophobia === 2}
                  setSelect={() => {
                    setPhotophobia(2);
                    setCount(count + 1);
                  }}
                />
              </div>
            </div>
          </ContentQuestionary>
        )}
      </>
    </PageModel>
  );
}
