import { IoMdClose } from "react-icons/io";
import "./styles.css";

interface ModalVideoProps {
  videoURL: string;
  onClose: () => void;
}

export default function ModalVideo({ videoURL, onClose }: ModalVideoProps) {
  // Função para converter o URL para o formato embed, se for um link do YouTube
  const getEmbedURL = (url: string) => {
    try {
      const videoUrl = new URL(url);

      if (videoUrl.hostname === "www.youtube.com" || videoUrl.hostname === "youtube.com") {
        const videoID = videoUrl.searchParams.get("v");
        return `https://www.youtube.com/embed/${videoID}`;
      }

      if (videoUrl.hostname === "youtu.be") {
        const videoID = videoUrl.pathname.slice(1);
        return `https://www.youtube.com/embed/${videoID}`;
      }

      // Retorna a URL original se não precisar de modificação
      return url;
    } catch (error) {
      console.error("URL inválida:", error);
      return url;
    }
  };

  // URL de vídeo final que será usada para o embed
  const embedURL = getEmbedURL(videoURL);

  return (
    <div className="modal-video-component">
      <div className="modal-content">
        <button className="button-close" onClick={onClose}>
          <IoMdClose />
        </button>
        {embedURL ? (
          <iframe
            width="560"
            height="315"
            src={embedURL}
            title="Vídeo explicativo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <p>URL do vídeo não está disponível.</p>
        )}
      </div>
    </div>
  );
}
