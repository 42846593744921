import React from "react";
import { Range } from "react-range";

import "./styles.css";

interface RangeProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const RangeInput: React.FC<RangeProps> = ({ value, setValue }) => {
  return (
    <>
      <label className="label-hours">
        <strong>{value === 1 ? "1 hora" : `${value} horas`}</strong>
      </label>
      <Range
        step={1}
        min={0}
        max={12}
        values={[value]}
        onChange={(values) => setValue(values[0])}
        renderTrack={({ props, children }) => (
          <div {...props} className="range-bar">
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className="range-button">
            {props["aria-valuenow"]}
          </div>
        )}
        renderMark={({ props, index }) => (
          <div {...props} className="range-mark">
            <p className="number">{index}</p>
          </div>
        )}
      />
    </>
  );
};

export default RangeInput;
