import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import FormSelect from "../../../../components/FormSelect";
import BoxSuccess from "../../../../components/BoxSuccess";
import { LensContext } from "../../../../contexts/lensContext";
import ListItem from "../../../../components/ListItems";
import ModalDelete from "../../../../components/ModalDelete";
import { AntireflectionType } from "../../../../types/Categories";

import "./styles.css";

export default function RegisterAntireflections() {
  const navigate = useNavigate();

  const {
    fabricators,
    createAntireflection,
    editAntireflection,
    deleteAntireflection,
    antireflections,
  } = useContext(LensContext);

  const [name, setName] = useState("");
  const [fabricatorSelected, setFabricatorSelected] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [description, setDescription] = useState("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [itemEdit, setItemEdit] = useState<AntireflectionType>();

  const [idToDelete, setIdToDelete] = useState<string>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (fabricatorSelected.trim() === "") {
      setInputError(["Tem que escolher um fabricante"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      antireflections.filter(
        (item) =>
          item.name === name && item.fabricatorCod === fabricatorSelected
      ).length > 0
    ) {
      setInputError(["Esse tratamento já está cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createAntireflection(name, fabricatorSelected, description, videoURL)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    const id = itemEdit.id;
    const name = itemEdit.name;
    const fabricatorSelected = itemEdit.fabricatorCod;
    const description = itemEdit.description;
    const videoURL = itemEdit.videoURL;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (fabricatorSelected.trim() === "") {
      setInputError(["Tem que escolher um fabricante"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      antireflections.filter(
        (item) =>
          item.name === name &&
          item.fabricatorCod === fabricatorSelected &&
          item.id !== id
      ).length > 0
    ) {
      setInputError(["Esse tratamento já está cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editAntireflection(id, name, fabricatorSelected, description, videoURL)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => {
          setRegisterSuccess(false);
          setItemEdit(undefined);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onDelete(event: FormEvent) {
    event.preventDefault();

    if (!idToDelete) {
      return;
    }

    setItemEdit(undefined);

    deleteAntireflection(idToDelete)
      .then(() => {
        setRegisterSuccess(true);
        setIdToDelete(undefined);
        setTimeout(() => {
          setRegisterSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-antireflection-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar tratamento antireflexo</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && <BoxSuccess message={`Criado com sucesso`} />}

            <FormInput
              label="Nome"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormSelect
              label="Fabricante"
              required
              options={fabricators.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={fabricatorSelected}
              onChange={(value) => value && setFabricatorSelected(value.value)}
            />

            <FormInput
              label="Descrição"
              input="text-area"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

            <FormInput
              label="Vídeo informativo"
              value={videoURL}
              onChange={(event) => setVideoURL(event.target.value)}
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar tratamento antireflexo</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && <BoxSuccess message={`Editado com sucesso`} />}

            <FormInput
              label="Nome"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormSelect
              label="Fabricante"
              required
              value={itemEdit.fabricatorCod}
              options={fabricators.map((item) => {
                return { value: item.id, label: item.name };
              })}
              onChange={(value) =>
                value &&
                setItemEdit({ ...itemEdit, fabricatorCod: value.value })
              }
            />

            <FormInput
              label="Descrição"
              input="text-area"
              value={itemEdit.description ?? ""}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, description: event.target.value })
              }
            />

            <FormInput
              label="Vídeo informativo"
              value={itemEdit.videoURL ?? ""}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, videoURL: event.target.value })
              }
            />

            <FormButton children="Editar" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        {idToDelete && (
          <ModalDelete
            nameItem={
              antireflections.find((item) => item.id === idToDelete)?.name ?? ""
            }
            onDelete={onDelete}
            onCancel={() => setIdToDelete(undefined)}
          />
        )}

        <ListItem
          render={antireflections.map((item) => {
            return {
              item: [
                fabricators.find(
                  (fabricator) => fabricator.id === item.fabricatorCod
                )?.name ?? "",
                item.name,
              ],
              hasDescription:
                item.description !== undefined && item.description !== "",
              hasVideo: item.videoURL !== undefined && item.videoURL !== "",
              onEdit: () => {
                setItemEdit(item);
              },
              onDelete: () => {
                setIdToDelete(item.id);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
