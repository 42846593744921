import { useEffect } from "react"
import { FormEvent, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import FormButton from "../../components/FormButton"
import FormInput from "../../components/FormInput"
import Loading from "../../components/Loading"
import { LoginContext } from "../../contexts/loginContext"
import { ResponseContext } from "../../contexts/responseContext"

import "./styles.css"

export default function Login() {
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const { login } = useContext(LoginContext)

  const { cleanResponses } = useContext(ResponseContext)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    cleanResponses()
    // eslint-disable-next-line
  }, [])

  function onLogin(e: FormEvent) {
    e.preventDefault()

    if (email === "" || password === "") {
      alert(`Digite ${email === "" ? "o email" : "a senha"}`)
      return
    }

    setLoading(true)

    login(email, password)
      .then((res) => {
        if (res.ok) {
          navigate("/")
        } else {
          if (res.error?.code === "auth/wrong-password") {
            alert("Senha incorreta")
            return
          }

          if (res.error?.code === "auth/user-not-found") {
            alert("Usuário não cadastrado")
            return
          }

          if(res.error?.code === "Consultant not found") {
            alert("Consultor não cadastrado")
          }

          alert("Erro ao fazer login")
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="login-page">
      <form onSubmit={onLogin}>
        <img
          src="/assets/logos/MARCA_HORIZONTAL_01.svg"
          alt="My Best Vision"
          className="logo"
        />

        <h2>Login</h2>

        <FormInput
          label="Email"
          type="text"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <FormInput
          label="Senha"
          type="password"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <FormButton
          type="submit"
          className={loading ? "button-loading" : ""}
          disabled={loading}
        >
          {loading ? <Loading size={30} transparency /> : "Entrar"}
        </FormButton>
      </form>
    </div>
  )
}
