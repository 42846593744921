import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import PageModel from "../../../components/PageModel";
import ContentQuestionary from "../../../components/ContentQuestionary";

import contactLensImage from "../../../assets/contact-lens.jpg";

import "./styles.css";

export default function ContactLensesPage() {
  const navigate = useNavigate();

  const { setSwitchedContactLens } = useContext(QuestionaryContext);

  function previous() {
    navigate("/secondpair");
  }

  function notContactLens() {
    setSwitchedContactLens("não");
    navigate("/congratulations");
  }

  function next() {
    setSwitchedContactLens("sim");
    navigate("/congratulations");
  }

  return (
    <PageModel title="Lentes de contato" previous={previous}>
      <ContentQuestionary srcImage={contactLensImage}>
        <p className="explanation">
          <strong>Você sabia?</strong>
          <p>
            Apesar de não substituiresm os óculos, as lentes de contato são uma
            excelente opção para quem quer ir a algum evento e não pode ou não
            quer utilizar seus óculos como por exemplo em festas de casamento,
            formaturas ou mesmo praticar algum esporte em os óculos atrapalham
            ou não podem ser incluído o grau.
          </p>
        </p>

        <div>
          <button
            className="contact-lenses-button-primary"
            onClick={() => next()}
          >
            Quero lentes de contato
          </button>
          <button
            className="contact-lenses-button-secondary"
            onClick={() => notContactLens()}
          >
            Não quero
          </button>
        </div>
      </ContentQuestionary>
    </PageModel>
  );
}
