import { useState, ReactNode } from "react";
import "./styles.css";

interface ContentQuestionaryProps {
  children: ReactNode;
  srcImage?: string;
}

export default function ContentQuestionary({
  children,
  srcImage,
}: ContentQuestionaryProps) {
  const [isLoadImage, setIsLoadImage] = useState(srcImage === undefined);

  return (
    <div className="content-questionary">
      <div className="action-container">{isLoadImage && children}</div>
      {srcImage && (
        <div className="image-container">
          <img
            src={srcImage}
            onLoad={() => setIsLoadImage(true)}
            alt="Homem dentro de um carro"
          />
        </div>
      )}
    </div>
  );
}
