import { useState } from "react"
import { useEffect } from "react"
import { ReactNode } from "react"
import HeaderQuestionary from "../HeaderQuestionary"
import Pagination from "../Pagination"

import "./styles.css"

interface PageModelProps {
  title: string
  children: ReactNode
  previous: () => void
  next?: () => void
  noTranstion?: boolean
  number?: number
}

let timeout: NodeJS.Timeout

export default function PageModel({
  children,
  title,
  previous,
  next,
  noTranstion,
  number,
}: PageModelProps) {
  const [transition, setTransition] = useState(!noTranstion)

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setTransition(false)
    }, 200)
  }, [])

  return (
    <div className="page-model-component">
      <main className={transition ? "transition" : ""}>
        <HeaderQuestionary
          title={title}
          previous={() => previous()}
          next={next ? next : undefined}
        />

        {children}

        {number && <Pagination number={number} />}
      </main>
    </div>
  )
}
