import { ReactNode } from "react";
import "./styles.css";

interface ModalInformationProps {
  children?: ReactNode;
  text: string;
  onClose: () => void;
  label?: string;
}

export default function ModalInformation({
  children,
  onClose,
  label,
  text,
}: ModalInformationProps) {
  return (
    <div className="modal-information-component">
      <div className="content">
        {children}

        <strong>{text}</strong>

        <button className="exit-button" onClick={() => onClose()}>
          {label ? label : "OK"}
        </button>
      </div>
    </div>
  );
}
