import React from "react";

import logoMercadotica from "../../assets/logo.png";

import "./styles.css";

type HeaderProps = {
  children?: React.ReactNode
}

const Header = ({ children }: HeaderProps) => {
  return (
    <header className="header-pages">
      {children}
      <img src={logoMercadotica} alt="logo mercadotica" className="logo" />
    </header>
  );
};

export default Header;
