import { createContext, ReactNode, useEffect, useState } from "react";
import ShopType from "../types/Shop";
import { deleteDocument, getAllDocuments, updateDocument } from "../controller/firebaseController";

interface ShopContextData {
  shops: ShopType[];
  createShop: (name: string, id: string) => Promise<void>;
  editShop: (name: string, id: string) => Promise<void>;
  deleteShop: (id: string) => Promise<void>;
}

interface ShopProviderProps {
  children: ReactNode;
}

export const ShopContext = createContext({} as ShopContextData);

export function ShopProvider({ children }: ShopProviderProps) {
  const [shops, setShops] = useState<ShopType[]>([]);

  useEffect(() => {
    loadShops();
  }, []);

  async function loadShops() {
    const res = await getAllDocuments("shops")

    setShops(
      res.docs.map((item) => {
        return { name: item.data().name, id: item.id, equipament: item.data().equipament };
      })
    );
  }

  async function createShop(name: string, id: string) {
    await updateDocument("shops", id, { name });
  }

  async function editShop(name: string, id: string) {
    await updateDocument("shops", id, { name });
  }

  async function deleteShop(id: string) {
    await deleteDocument("shops", id);
  }

  return (
    <ShopContext.Provider value={{ shops, createShop, editShop, deleteShop }}>
      {children}
    </ShopContext.Provider>
  );
}
