import { FormEvent } from "react";
import "./styles.css";

interface ModalDeleteProps {
  nameItem: string;
  onDelete: (event: FormEvent) => void;
  onCancel: (event: FormEvent) => void;
}

export default function ModalDelete({
  nameItem,
  onDelete,
  onCancel,
}: ModalDeleteProps) {
  return (
    <div className="modal-delete-component">
      <form className="box-modal" onSubmit={onCancel} onReset={onDelete}>
        <h3>Confirma a exclusão de {nameItem} ?</h3>

        <div className="box-button">
          <button type="reset">Sim</button>
          <button type="submit">Não</button>
        </div>
      </form>
    </div>
  );
}
