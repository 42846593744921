import { useContext, useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import ListItem from "../../../components/ListItems";
import Loading from "../../../components/Loading";
import { ResponseContext } from "../../../contexts/responseContext";
import { ResponseCompleteType } from "../../../types/Response";
import birthDateForAge from "../../../utils/birthDateForAge";

import "./styles.css";

export default function Reports() {
  const navigate = useNavigate();

  const { getResponses, responses } = useContext(ResponseContext);

  const [openedResponse, setOpenedResponse] = useState<ResponseCompleteType>();

  useEffect(() => {
    console.log(responses)
    if (responses === undefined) {
      getResponses();
    }
    // eslint-disable-next-line
  }, []);

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="client-page">
      <Header>
        <div className="actions-header">
          <button
            className="go-back-button"
            onClick={() => goBack()}
            title="Voltar"
          >
            <BsArrowLeftShort className="icon" size={30} />
          </button>
        </div>
      </Header>

      <main>
        {openedResponse ? (
          <>
            <div className="table-report">
              <IoIosCloseCircleOutline
                className="button-close"
                onClick={() => setOpenedResponse(undefined)}
              />
              <div className="item-report">
                <strong>Cliente</strong>
                <p>{openedResponse.client.name}</p>
              </div>
              <div className="item-report">
                <strong>Idade</strong>
                <p>{birthDateForAge(openedResponse.client.birth)} anos</p>
              </div>

              {openedResponse.highlights.length > 0 ? (
                <>
                  <h3>Pontos importantes</h3>
                  {openedResponse.highlights.map((item) => {
                    return (
                      <div className="item-report">
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </>
              ) : null}

              <h3>Dioptria</h3>

              <div className="item-report">
                <strong>Adição</strong>
                <p>{openedResponse.prescription.adition}</p>
              </div>

              <h5>Olho Esquerdo</h5>
              <div className="item-report">
                <strong>ESF</strong>
                <p>{openedResponse.prescription.leftEye.esf}</p>
              </div>
              <div className="item-report">
                <strong>CIL</strong>
                <p>{openedResponse.prescription.leftEye.cil}</p>
              </div>
              <div className="item-report">
                <strong>EIXO</strong>
                <p>{openedResponse.prescription.leftEye.eixo}</p>
              </div>

              <h5>Olho Esquerdo</h5>
              <div className="item-report">
                <strong>ESF</strong>
                <p>{openedResponse.prescription.rightEye.esf}</p>
              </div>
              <div className="item-report">
                <strong>CIL</strong>
                <p>{openedResponse.prescription.rightEye.cil}</p>
              </div>
              <div className="item-report">
                <strong>EIXO</strong>
                <p>{openedResponse.prescription.rightEye.eixo}</p>
              </div>

              <h3>Lente escolhida</h3>
              <div className="item-report">
                <strong>Nome</strong>
                <p>{openedResponse.lens.design}</p>
              </div>
              <div className="item-report">
                <strong>Marca</strong>
                <p>{openedResponse.lens.brand}</p>
              </div>
              <div className="item-report">
                <strong>Índice de refração</strong>
                <p>{openedResponse.lens.refractionIndex}</p>
              </div>
              <div className="item-report">
                <strong>Fotossensível</strong>
                <p>{openedResponse.lens.technology}</p>
              </div>
              <div className="item-report">
                <strong>Tratamento Antirreflexo</strong>
                <p>{openedResponse.lens.antireflection}</p>
              </div>
              <div className="item-report">
                <strong>Segundo Par</strong>
                <p>{openedResponse.secondPair}</p>
              </div>
              <div className="item-report">
                <strong>Lente de contato</strong>
                <p>{openedResponse.contactLens}</p>
              </div>
            </div>
          </>
        ) : responses ? (
          <ListItem
            titles={["Nome do cliente", "Nome do consultor", "Data"]}
            render={responses.filter((response) => response.client?.name !== undefined).map((item) => {
              return {
                item: [
                  item.client ? item.client.name : "",
                  item.consultant ? item.consultant.name : "",
                  Intl.DateTimeFormat("pt-br").format(
                    item.timestamp.seconds * 1000
                  ),
                ],
                onDetails: () => {
                  setOpenedResponse(item);
                },
              };
            })}
          />
        ) : (
          <Loading />
        )}
      </main>
    </div>
  );
}
