import ConsultantType from "../types/Consultant";
import ResponseType from "../types/Response";
import { getDocumentByParam } from "../controller/firebaseController";


export async function getTotalClients(shop: string) {
  let totalClients = 0;

  const response = await getDocumentByParam("consultants", "shop", shop);

  const dataConsultant = response.docs.map((item) => {
    return { ...item.data(), id: item.id } as ConsultantType;
  });

  for (let i = 0; i < dataConsultant.length; i++) {
    const res = await getDocumentByParam("responses", "consultantEmail", dataConsultant[i].email);

    const dataReports = res.docs.map((item) => {
      return { ...item.data(), id: item.id } as ResponseType;
    });

    totalClients += dataReports.length;
  }
  
  return totalClients;
}