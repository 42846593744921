export default function birthDateForAge(date: string) {
  const birthDay = Number(date.split("-")[2]);
  const birthMonth = Number(date.split("-")[1]) + 1;
  const birthYear = Number(date.split("-")[0]);

  const dateNow = new Date();

  const nowDay = dateNow.getDate();
  const nowMonth = dateNow.getMonth() + 1;
  const nowYear = dateNow.getFullYear();

  if (nowYear < birthYear) {
    return 0;
  }

  var year = nowYear - birthYear;
  var month = nowMonth - birthMonth;
  var day = nowDay - birthDay;

  if (day < 0) {
    month--;
  }

  if (month < 0) {
    year--;
  }

  return year;
}
