import { FormEvent, useContext, useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import FormSelect from "../../../../components/FormSelect";
import { LensContext } from "../../../../contexts/lensContext";
import ModalDelete from "../../../../components/ModalDelete";

import "./styles.css";
import LensType from "../../../../types/Lens";
import GenerateRelatory from "../../../../components/GenerateRelatory";

const equipamentArray = [
  { value: 'FitPAD', label: 'FitPAD' },
  { value: 'Visioffice', label: 'Visioffice' },
];

export default function RegisterLenses() {
  const navigate = useNavigate();

  const {
    antireflections,
    brands,
    designs,
    fabricators,
    families,
    lenses,
    refractionIndexs,
    technologies,
    createLens,
    editLens,
    deleteLens,
  } = useContext(LensContext);

  const [cod, setCod] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [fabricatorSelected, setFabricatorSelected] = useState("");
  const [brandSelected, setBrandSelected] = useState("");
  const [designSelected, setDesignSelected] = useState("");
  const [familySelected, setFamilySelected] = useState("");
  const [refractionIndexSelected, setRefractionIndexSelected] = useState("");
  const [antireflectionSelected, setAntireflectionSelected] = useState("");
  const [technologySelected, setTechnologySelected] = useState("");
  const [equipaments, setEquipaments] = useState(false);
  const [equipamentSelected, setEquipamentSelected] = useState("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [itemEdit, setItemEdit] = useState<LensType>();

  const [idToDelete, setIdToDelete] = useState<string>();

  useEffect(() => {
    let design = "",
      refractionIndex = "",
      technology = "",
      antireflection = "";

    if (designSelected !== "") {
      const selectedDesign = designs.find((item) => item.id === designSelected);
      design = selectedDesign ? selectedDesign.name : "";
    }

    if (refractionIndexSelected !== "") {
      const selectedRefractionIndex = refractionIndexs.find(
        (item) => item.id === refractionIndexSelected
      );
      refractionIndex = selectedRefractionIndex ? selectedRefractionIndex.name : "";
    }

    if (technologySelected !== "") {
      const selectedTechnology = technologies.find(
        (item) => item.id === technologySelected
      );
      technology = selectedTechnology ? selectedTechnology.name : "";
    }

    if (antireflectionSelected !== "") {
      const selectedAntireflection = antireflections.find(
        (item) => item.id === antireflectionSelected
      );
      antireflection = selectedAntireflection ? selectedAntireflection.name : "";
    }

    const nameBuild = [design, refractionIndex, technology, antireflection]
      .filter((item) => item.trim() !== "")
      .join(" ");
    setName(nameBuild);
  }, [
    designSelected,
    refractionIndexSelected,
    technologySelected,
    antireflectionSelected,
    designs,
    refractionIndexs,
    technologies,
    antireflections,
  ]);

  useEffect(() => {
    let design = "",
      refractionIndex = "",
      technology = "",
      antireflection = "";

    if (!itemEdit) {
      return;
    }

    if (itemEdit.design !== "") {
      const selectedDesign = designs.find((item) => item.id === itemEdit.design);
      design = selectedDesign ? selectedDesign.name : "";
    }

    if (itemEdit.refractionIndex !== "") {
      const selectedRefractionIndex = refractionIndexs.find(
        (item) => item.id === itemEdit.refractionIndex
      );
      refractionIndex = selectedRefractionIndex ? selectedRefractionIndex.name : "";
    }

    if (itemEdit.technology !== "") {
      const selectedTechnology = technologies.find(
        (item) => item.id === itemEdit.technology
      );
      technology = selectedTechnology ? selectedTechnology.name : "";
    }

    if (itemEdit.antireflection !== "") {
      const selectedAntireflection = antireflections.find(
        (item) => item.id === itemEdit.antireflection
      );
      antireflection = selectedAntireflection ? selectedAntireflection.name : "";
    }

    const nameBuild = [design, refractionIndex, technology, antireflection]
      .filter((item) => item.trim() !== "")
      .join(" ");

    if (itemEdit.name !== nameBuild) {
      setItemEdit({ ...itemEdit, name: nameBuild });
    }
  }, [itemEdit, designs, refractionIndexs, technologies, antireflections]);

  useEffect(() => {
    setBrandSelected("");
    setAntireflectionSelected("");
    setTechnologySelected("");
  }, [fabricatorSelected]);

  useEffect(() => {
    setDesignSelected("");
  }, [brandSelected]);

  function onRegister(event: FormEvent) {
    event.preventDefault();

    document
      .getElementsByClassName("register-lenses-page")[0]
      .scroll({ top: 0, behavior: "smooth" });

    if (
      cod === "" ||
      brandSelected === "" ||
      fabricatorSelected === "" ||
      designSelected === "" ||
      familySelected === "" ||
      price === "" ||
      refractionIndexSelected === ""
    ) {
      setInputError(["Preencha os campos obrigatórios"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      lenses.some((item) => item.name === name || item.cod === cod)
    ) {
      setInputError(["Essa lente já está cadastrada"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createLens(
      name,
      Number(price),
      fabricatorSelected,
      brandSelected,
      designSelected,
      familySelected,
      refractionIndexSelected,
      antireflectionSelected,
      technologySelected
    )
      .then(() => {
        setRegisterSuccess(true);
        setPrice("");
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    const id = itemEdit.id;
    const cod = itemEdit.cod;
    const name = itemEdit.name;
    const price = Number(itemEdit.price);
    const fabricatorSelected = itemEdit.fabricator;
    const brandSelected = itemEdit.brand;
    const designSelected = itemEdit.design;
    const familySelected = itemEdit.family;
    const refractionIndexSelected = itemEdit.refractionIndex;
    const antireflectionSelected = itemEdit.antireflection;
    const technologySelected = itemEdit.technology;

    document
      .getElementsByClassName("register-lenses-page")[0]
      .scroll({ top: 0, behavior: "smooth" });

    if (
      brandSelected.trim() === "" ||
      fabricatorSelected === "" ||
      designSelected === "" ||
      familySelected === "" ||
      price === 0 ||
      refractionIndexSelected === ""
    ) {
      setInputError(["Preencha os campos obrigatórios"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      lenses.some(
        (item) =>
          (item.name === name || (item.cod === cod && cod !== "")) &&
          item.id !== id
      )
    ) {
      setInputError(["Essa lente já está cadastrada"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editLens({
      id,
      cod,
      name,
      price,
      fabricator: fabricatorSelected,
      brand: brandSelected,
      design: designSelected,
      family: familySelected,
      refractionIndex: refractionIndexSelected,
      antireflection: antireflectionSelected,
      technology: technologySelected,
      refractionIndexs:[refractionIndexSelected]
    })
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => {
          setRegisterSuccess(false);
          setItemEdit(undefined);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onDelete(event: FormEvent) {
    event.preventDefault();

    if (!idToDelete) {
      return;
    }

    setItemEdit(undefined);

    deleteLens(idToDelete)
      .then(() => {
        setRegisterSuccess(true);
        setIdToDelete(undefined);
        setTimeout(() => {
          setRegisterSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  const handleClick = () => setEquipaments(!equipaments);

  return (
    <div className="register-lenses-page">
      <Header>
        <button className="logout-button" onClick={goBack} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
          {lenses.length}
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar lente</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Lente cadastrada com sucesso`} />
            )}

            <p>
              <b>Nome:</b> {name}
            </p>
            <br />

            <FormInput
              label="Código"
              required
              value={cod}
              onChange={(event) => setCod(event.target.value)}
            />

            <FormSelect
              label="Fabricante"
              required
              options={fabricators.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={fabricatorSelected}
              onChange={(value) => value && setFabricatorSelected(value.value)}
            />

            <FormSelect
              label="Marca"
              required
              options={brands
                .filter((item) => item.fabricatorCod === fabricatorSelected)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              value={brandSelected}
              onChange={(value) => value && setBrandSelected(value.value)}
            />

            <FormSelect
              label="Desenho"
              required
              value={designSelected}
              options={designs
                .filter((item) => item.brandCod === brandSelected)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              onChange={(value) => value && setDesignSelected(value.value)}
            />

            <FormSelect
              label="Família"
              required
              value={familySelected}
              options={families.map((item) => {
                return { label: item.name, value: item.id };
              })}
              onChange={(value) => value && setFamilySelected(value.value)}
            />

            <FormSelect
              label="Índice de refração"
              required
              value={refractionIndexSelected}
              options={
                designs
                  .find((item) => item.id === designSelected)
                  ?.refractionIndexs.map((item) => {
                    const refractionIndex = refractionIndexs.find(
                      (index) => index.name === item
                    );

                    return {
                      label: refractionIndex?.name ?? "",
                      value: refractionIndex?.id ?? "",
                    };
                  }) ?? []
              }
              onChange={(value) =>
                value && setRefractionIndexSelected(value.value)
              }
            />

            <FormSelect
              label="Antireflexo"
              value={antireflectionSelected}
              options={antireflections
                .filter((item) => item.fabricatorCod === fabricatorSelected)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              onChange={(value) =>
                value && setAntireflectionSelected(value.value)
              }
              menuPlacement="top"
            />

            <FormSelect
              label="Tecnologia"
              value={technologySelected}
              options={technologies
                .filter((item) => item.fabricatorCod === fabricatorSelected)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              onChange={(value) => value && setTechnologySelected(value.value)}
              menuPlacement="top"
            />

            <FormInput
              label="Preço"
              required
              type="number"
              value={price}
              onChange={(event) => setPrice(event.target.value)}
            />
            <div style={{ display: 'flex', flexDirection: 'row', color: 'gray' }}>
              <input name="checkboxn" type="checkbox" checked={equipaments} onChange={handleClick} />
              <label htmlFor="checkboxn" style={{ marginLeft: '8px' }} >Existe equipamento específico?</label>
            </div>

            {equipaments && (
              <FormSelect
                label="Equipamento"
                options={
                  equipamentArray.map((item) => {
                    return { label: item.label, value: item.value };
                  })
                }
                value={equipamentSelected}
                onChange={(value) =>
                  value && setEquipamentSelected(value.value)
                }
              />
            )}

            <FormButton children="Cadastrar" type="submit" />
          </form>
        ) : (
          <form className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar lente</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Lente editada com sucesso`} />
            )}

            <p>
              <b>Nome:</b> {itemEdit.name}
            </p>
            <br />

            <FormInput
              label="Código"
              required
              value={itemEdit.cod}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, cod: event.target.value })
              }
            />

            <FormSelect
              label="Fabricante"
              required
              options={fabricators.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={itemEdit.fabricator}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, fabricator: value.value })
              }
            />

            <FormSelect
              label="Marca"
              required
              options={brands
                .filter((item) => item.fabricatorCod === itemEdit.fabricator)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              value={itemEdit.brand}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, brand: value.value })
              }
            />

            <FormSelect
              label="Desenho"
              required
              options={designs
                .filter((item) => item.brandCod === itemEdit.brand)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              value={itemEdit.design}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, design: value.value })
              }
            />

            <FormSelect
              label="Família"
              required
              options={families.map((item) => {
                return { label: item.name, value: item.id };
              })}
              value={itemEdit.family}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, family: value.value })
              }
            />

            <FormSelect
              label="Índice de refração"
              required
              options={
                designs
                  .find((item) => item.id === itemEdit.design)
                  ?.refractionIndexs.map((item) => {
                    const refractionIndex = refractionIndexs.find(
                      (index) => index.name === item
                    );

                    return {
                      label: refractionIndex?.name ?? "",
                      value: refractionIndex?.id ?? "",
                    };
                  }) ?? []
              }
              value={itemEdit.refractionIndex}
              onChange={(value) =>
                value &&
                setItemEdit({ ...itemEdit, refractionIndex: value.value })
              }
            />

            <FormSelect
              label="Antireflexo"
              options={antireflections
                .filter((item) => item.fabricatorCod === itemEdit.fabricator)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              value={itemEdit.antireflection}
              onChange={(value) =>
                value &&
                setItemEdit({ ...itemEdit, antireflection: value.value })
              }
            />

            <FormSelect
              label="Tecnologia"
              options={technologies
                .filter((item) => item.fabricatorCod === itemEdit.fabricator)
                .map((item) => {
                  return { label: item.name, value: item.id };
                })}
              value={itemEdit.technology}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, technology: value.value })
              }
            />

            <FormInput
              label="Preço"
              required
              type="number"
              value={itemEdit.price}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, price: event.target.value })
              }
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => {
                setItemEdit(undefined);
              }}
            />
          </form>
        )}

        {idToDelete && (
          <ModalDelete
            nameItem={lenses.find((item) => item.id === idToDelete)?.name ?? ""}
            onDelete={onDelete}
            onCancel={() => setIdToDelete(undefined)}
          />
        )}

        <GenerateRelatory
          titles={["Nome da lente", "Fabricante", "Preço"]}
          relatory={lenses}
          render={lenses.map((item) => {
            const fabricator = fabricators.find(
              (fabricator) => fabricator.id === item.fabricator
            );
            const fabricatorName = fabricator ? fabricator.name : "Fabricante desconhecido";
            return {
              item: [
                item.name,
                `${fabricatorName} `,`${item.cod}`, // Adiciona o código ao lado do nome do fabricante
                Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.price),
              ],
              hasDescription: item.cod ? true : false,
              onEdit: () => {
                setItemEdit(item);
              },
              onDelete: () => {
                setIdToDelete(item.id);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
