import { createContext, ReactNode, useEffect, useState } from "react";
import { DesignAttributeType } from "../types/DesignAttributes";
import { addDocument, getAllDocuments, updateDocument } from "../controller/firebaseController";

interface DesignAttributesContextData {
  designAttributes: DesignAttributeType[];
  createAttribute: (
    name: string,
    description: string,
    logoURL?: string
  ) => Promise<void>;
  editAttribute: (
    id: string,
    name: string,
    description: string,
    logoURL?: string
  ) => Promise<void>;
}

interface DesignAttributesProviderProps {
  children: ReactNode;
}

export const DesignAttributesContext = createContext(
  {} as DesignAttributesContextData
);

export function DesignAttributesProvider({
  children,
}: DesignAttributesProviderProps) {
  const [designAttributes, setDesignAttributes] = useState<
    DesignAttributeType[]
  >([]);

  useEffect(() => {
    loadDesignAttributes();
  }, []);

  async function loadDesignAttributes() {
    const res = await getAllDocuments("desingAttributes")

    setDesignAttributes(
      res.docs.map((item) => {
        const data = item.data() as DesignAttributeType;
        return { ...data, id: item.id };
      })
    );
  }

  async function createAttribute(
    name: string,
    description: string,
    logoURL?: string
  ) {
    await addDocument("desingAttributes", { name, description, logoURL: logoURL ?? "" })
  }

  async function editAttribute(
    id: string,
    name: string,
    description: string,
    logoURL?: string
  ) {
    return await updateDocument("desingAttributes", id, { name, description, logoURL: logoURL ?? "" })
  }

  return (
    <DesignAttributesContext.Provider
      value={{
        designAttributes,
        createAttribute,
        editAttribute,
      }}
    >
      {children}
    </DesignAttributesContext.Provider>
  );
}
