import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "./styles.css";

interface HeaderQuestionaryProps {
  title: string;
  previous: () => void;
  next?: () => void;
}

export default function HeaderQuestionary({
  title,
  previous,
  next,
}: HeaderQuestionaryProps) {
  return (
    <header className="header-questionary-component">
      <div className="content">
        <button onClick={() => previous()}>
          <div className="back-text">
            Voltar
          </div>
          <MdKeyboardArrowLeft />
        </button>
        <h1>{title}</h1>
        <button disabled={!next} onClick={() => next && next()}>
          <div className="next-text">
            Avançar
          </div>
          <MdKeyboardArrowRight />
        </button>
      </div>
    </header>
  );
}
