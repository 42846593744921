import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AntireflectionType,
  BaseCategoriesType,
  CategoryWithFabricator,
  DesignType,
} from "../types/Categories";
import LensType from "../types/Lens";
import { PrescriptionType } from "../types/Prescription";
import birthDateForAge from "../utils/birthDateForAge";
import diopterCalculation from "../utils/questionaryFunctions/diopterCalculation";
import getHighlights from "../utils/questionaryFunctions/getHighlights";
import visionProblems from "../utils/questionaryFunctions/visionProblems";
import { ClientContext } from "./clientContext";
import { LensContext } from "./lensContext";

const initialState = {
  prescription: {
    rightEye: { esf: "0.00", cil: "", eixo: "" },
    leftEye: { esf: "0.00", cil: "", eixo: "" },
    adition: "",
  } as PrescriptionType,
  frames: "",
  useGlasses: "não",
  usedDesign: "",
  shortActivityTime: 0,
  interActivityTime: 0,
  mobileDeviceTime: 0,
  desktopDeviceTime: 0,
  fixedDeviceTime: 0,
  drive: -1,
  useContactLens: -1,
  outdoorActivity: -1,
  photophobia: -1,
  switchedLens: {
    id: "",
    price: 0,
    name: "",
    design: "",
    brand: "",
    family: "",
    fabricator: "",
    refractionIndex: "",
    technology: "",
    antireflection: "",
  } as LensType,
  switchedContactLens: "",
  switchedSecondPair: [],
};

export interface QuestionaryContextData {
  saveInStorage: (state: number) => void;
  clearStorage: () => void;
  //Questao 1
  prescription: PrescriptionType;
  setPrescription: React.Dispatch<React.SetStateAction<PrescriptionType>>;
  frames: string;
  setFrames: React.Dispatch<React.SetStateAction<string>>;
  useGlasses: string;
  setUseGlasses: React.Dispatch<React.SetStateAction<string>>;
  glassesSelected: string;
  setGlassesSelected: React.Dispatch<React.SetStateAction<string>>;
  //Questao 2
  shortActivityTime: number;
  setShortActivityTime: React.Dispatch<React.SetStateAction<number>>;
  interActivityTime: number;
  setInterActivityTime: React.Dispatch<React.SetStateAction<number>>;
  //Questao 3
  mobileDeviceTime: number;
  setMobileDeviceTime: React.Dispatch<React.SetStateAction<number>>;
  desktopDeviceTime: number;
  setDesktopDeviceTime: React.Dispatch<React.SetStateAction<number>>;
  fixedDeviceTime: number;
  setFixedDeviceTime: React.Dispatch<React.SetStateAction<number>>;
  //Questao 4
  drive: number;
  setDrive: React.Dispatch<React.SetStateAction<number>>;
  useContactLenses: number;
  setUseContactLenses: React.Dispatch<React.SetStateAction<number>>;
  //Questao 5
  outdoorActivity: number;
  setOutdoorActivity: React.Dispatch<React.SetStateAction<number>>;
  photophobia: number;
  setPhotophobia: React.Dispatch<React.SetStateAction<number>>;
  //Lente recomendada
  switchedLens: LensType;
  setSwitchedLens: React.Dispatch<React.SetStateAction<LensType>>;
  switchedContactLens: string;
  setSwitchedContactLens: React.Dispatch<React.SetStateAction<string>>;
  switchedSecondPair: string[];
  setSwitchedSecondPair: React.Dispatch<React.SetStateAction<string[]>>;
  //Funcoes
  visionProblem: () => string[];
  getIndicateLenses: () => DesignType[];
  getPhotosensitiveOptions: () => {
    recommendation: string;
    options: CategoryWithFabricator[];
    drive: number;
    emptyOption: boolean;
  };
  getRefractionIndex: () => {
    options: { id: string; index: string; selected?: boolean }[];
    comment: string;
  };
  getAntireflectionOptions: () => {
    options: CategoryWithFabricator[];
    emptyOption: boolean;
  };
  getIndicateSecondPair: () => {
    use: string;
    recommendation: number;
  };
  getIndicateContactLenses: () => boolean;
  getHighlights: () => string[];
  getLens: () => LensType | undefined;
}

interface QuestionaryProviderProps {
  children: ReactNode;
}

export const QuestionaryContext = createContext({} as QuestionaryContextData);

export function QuestionaryProvider({ children }: QuestionaryProviderProps) {
  const { client } = useContext(ClientContext);

  const {
    brands,
    fabricators,
    designs,
    technologies,
    lenses,
    antireflections,
    refractionIndexs,
  } = useContext(LensContext);

  //Questao 1
  const [prescription, setPrescription] = useState<PrescriptionType>(
    initialState.prescription
  );
  const [frames, setFrames] = useState<string>(initialState.frames);
  const [useGlasses, setUseGlasses] = useState(initialState.useGlasses);
  const [glassesSelected, setGlassesSelected] = useState(
    initialState.usedDesign
  );
  //Questao 2
  const [shortActivityTime, setShortActivityTime] = useState(
    initialState.shortActivityTime
  );
  const [interActivityTime, setInterActivityTime] = useState(
    initialState.interActivityTime
  );
  //Questao 3
  const [mobileDeviceTime, setMobileDeviceTime] = useState(
    initialState.mobileDeviceTime
  );
  const [desktopDeviceTime, setDesktopDeviceTime] = useState(
    initialState.desktopDeviceTime
  );
  const [fixedDeviceTime, setFixedDeviceTime] = useState(
    initialState.fixedDeviceTime
  );
  //Questao 4
  const [drive, setDrive] = useState(-1);
  const [useContactLenses, setUseContactLenses] = useState(
    initialState.useContactLens
  );
  //Questao 5
  const [outdoorActivity, setOutdoorActivity] = useState(
    initialState.outdoorActivity
  );
  const [photophobia, setPhotophobia] = useState(initialState.photophobia);
  //Lente recomendada
  const [switchedLens, setSwitchedLens] = useState<LensType>(
    initialState.switchedLens
  );
  const [switchedContactLens, setSwitchedContactLens] = useState(
    initialState.switchedContactLens
  );
  const [switchedSecondPair, setSwitchedSecondPair] = useState<string[]>(
    initialState.switchedSecondPair
  );

  const [isLoading, setIsLoading] = useState(true);

  //Pega as informacoes salvas no session storage
  useEffect(() => {
    loadStorage();
  }, []);

  //Salvar informacoes da escolha da lente quando houver modificacao no valor
  useEffect(() => {
    saveInStorage();
    // eslint-disable-next-line
  }, [
    prescription,
    frames,
    useGlasses,
    glassesSelected,
    shortActivityTime,
    interActivityTime,
    mobileDeviceTime,
    desktopDeviceTime,
    drive,
    useContactLenses,
    outdoorActivity,
    photophobia,
    switchedLens,
    switchedContactLens,
    switchedSecondPair,
  ]);

  function loadStorage() {
    const storage11 = sessionStorage.getItem("1.1");
    const storage12 = sessionStorage.getItem("1.2");
    const storage13 = sessionStorage.getItem("1.3");
    const storage14 = sessionStorage.getItem("1.4");
    const storage21 = sessionStorage.getItem("2.1");
    const storage22 = sessionStorage.getItem("2.1");
    const storage31 = sessionStorage.getItem("3.1");
    const storage32 = sessionStorage.getItem("3.2");
    const storage33 = sessionStorage.getItem("3.3");
    const storage41 = sessionStorage.getItem("4.1");
    const storage42 = sessionStorage.getItem("4.2");
    const storage51 = sessionStorage.getItem("5.1");
    const storage52 = sessionStorage.getItem("5.2");
    const storage6 = sessionStorage.getItem("6");
    const storage7 = sessionStorage.getItem("7");
    const storage8 = sessionStorage.getItem("8");

    if (storage11) setPrescription(JSON.parse(storage11) as PrescriptionType);
    if (storage12) setFrames(storage12);
    if (storage13) setUseGlasses(storage13);
    if (storage14) setGlassesSelected(storage14);

    if (storage21) setShortActivityTime(Number(storage21));
    if (storage22) setInterActivityTime(Number(storage22));

    if (storage31) setMobileDeviceTime(Number(storage31));
    if (storage32) setDesktopDeviceTime(Number(storage32));
    if (storage33) setFixedDeviceTime(Number(storage33));

    if (storage41) setDrive(JSON.parse(storage41));
    if (storage42) setUseContactLenses(JSON.parse(storage42));

    if (storage51) setOutdoorActivity(JSON.parse(storage51));
    if (storage52) setPhotophobia(JSON.parse(storage52));

    if (storage6) setSwitchedLens(JSON.parse(storage6));

    if (storage7) setSwitchedContactLens(storage7);

    if (storage8) setSwitchedContactLens(JSON.parse(storage8));

    setIsLoading(false);
  }
  //Salva informacoes gerais no session storage
  function saveInStorage() {
    sessionStorage.setItem("1.1", JSON.stringify(prescription));
    sessionStorage.setItem("1.2", frames);
    sessionStorage.setItem("1.3", useGlasses);
    sessionStorage.setItem("1.4", glassesSelected);
    sessionStorage.setItem("2.1", shortActivityTime.toString());
    sessionStorage.setItem("2.2", interActivityTime.toString());
    sessionStorage.setItem("3.1", mobileDeviceTime.toString());
    sessionStorage.setItem("3.2", desktopDeviceTime.toString());
    sessionStorage.setItem("3.3", fixedDeviceTime.toString());
    sessionStorage.setItem("4.1", drive.toString());
    sessionStorage.setItem("4.2", useContactLenses.toString());
    sessionStorage.setItem("5.1", outdoorActivity.toString());
    sessionStorage.setItem("5.2", photophobia.toString());
    sessionStorage.setItem("6", JSON.stringify(switchedLens));
    sessionStorage.setItem("7", switchedContactLens);
    sessionStorage.setItem("8", JSON.stringify(switchedSecondPair));
  }

  function clearStorage() {
    setPrescription(initialState.prescription);
    setFrames(initialState.frames);
    setUseGlasses(initialState.useGlasses);
    setGlassesSelected(initialState.usedDesign);

    setShortActivityTime(initialState.shortActivityTime);
    setInterActivityTime(initialState.interActivityTime);

    setMobileDeviceTime(initialState.mobileDeviceTime);
    setDesktopDeviceTime(initialState.desktopDeviceTime);
    setFixedDeviceTime(initialState.fixedDeviceTime);

    setDrive(initialState.drive);
    setUseContactLenses(initialState.useContactLens);

    setOutdoorActivity(initialState.outdoorActivity);
    setPhotophobia(initialState.photophobia);

    setSwitchedLens(initialState.switchedLens);

    setSwitchedContactLens(initialState.switchedContactLens);

    setSwitchedSecondPair(initialState.switchedSecondPair);
  }
  function getIndicateLenses(): DesignType[] {
    // Recupera a adição da prescrição
    const adition = prescription.adition;
    // Armazena o id das lentes recomendadas
    let indications = [] as string[];
    // Armazena o level das lentes disponíveis
    let levels = [] as number[];

    // Coleta o level das lentes que estão disponíveis
    // A função abrange todas as lentes disponíveis em designs, 
    // e adiciona os níveis delas no array levels se o nível da lente for maior ou igual a 8.
    designs.forEach((item) => {
      if (!levels.includes(item.level) && item.level >= 8) {
        levels.push(item.level);
      }
    });

    // Ordena os levels em ordem crescente
    levels.sort((a, b) => a - b);

    // Filtra lentes de acordo com a marca e fabricante (mantido como referência para todas as lentes)
    const availables = designs.filter(
      (item) =>
        brands.some(
          (brand) =>
            brand.id === item.brandCod &&
            fabricators.some(
              (fabricator) => brand.fabricatorCod === fabricator.id && fabricator.available
            )
        )
    );

    // Verifica o level das lentes usadas atualmente
    const usedLevel = designs.find((item) => item.id === glassesSelected)?.level;

    // Caso não tenha adição, as lentes são recomendadas com base na idade do cliente
    if (adition === "-" || adition === "") {
      const clienteBirth = birthDateForAge(client?.birth ?? "1990-01-01");
      // Eyezen start, Lentes Surfaçadas Essilor, Lentes Prontas Essilor
      //indications = [
      //"8bs6kAjhiaFQP0i09clt",
      //"CV4HY9JtK3qZqUnvrdAs",
      //"DMiTAITi6YlGd7MVsRx0",
      //];

      // Recomendação para clientes até 18 anos
      if (clienteBirth <= 18) {
        //kodak vs ou vs essilor surf (por grau escolherá um ou outro) eyezen kids 
        indications = ["CV4HY9JtK3qZqUnvrdAs", "FS9K2Ub7HaH1M0bTpBhx"];
      }


      // Recomendação para clientes maiores de 18 anos
      else if (clienteBirth > 18) {
        //kodak vs ou vs essilor surf (por grau escolherá um ou outro) eyezen boost
        indications = ["CV4HY9JtK3qZqUnvrdAs", "z8ErEdNYm72AN9qTL7RJ"]
          //} else if (clienteBirth >= 18 && clienteBirth < 40) {
          //indications = [
          //kodak precise uhd ,varilux physhio 360 ,varilux E
          //"Kj0HZJlKbFL0a6kYQ9ou",
          //"poqVQsXFSKLW5857ZYK7",
          //"FZV7AYmDYcjxC4udq8LW",
          //];
          ;
      }
      // Caso geral
      else {
        //varilux Liberty, Varilux E 
        indications = ["nbSYb9D9U3rQL0fcA4JR", "FZV7AYmDYcjxC4udq8LW"];
      }
    }
    // Quando o usuário seleciona a opção "não sei" para quais lentes está usando
    else if (!usedLevel) {
      // kodak precise uhd, Varilux comfort, Varilux xr design
      indications = ["Kj0HZJlKbFL0a6kYQ9ou", "O4UK0O4ke09178cIjn1Q", "OugK0Do1bL7JzB4P3HUl"];
    } else {
      // Caso a lente selecionada tenha um nível conhecido, pegamos a posição desse nível
      const position = levels.indexOf(usedLevel);

      // Se a lente selecionada for de outro fabricante, mostrar a equivalente da Essilor de mesmo nível
      let equivalentEssilorLenses = availables.filter(
        (item) => item.level === usedLevel && item.fabricator === "7gUldOCYksy3Q7A5BUxD"
      );

      // Se houver uma equivalente da Essilor, usamos essa
      if (equivalentEssilorLenses.length > 0) {
        indications = equivalentEssilorLenses.map((item) => item.id);

        // Calcula quantas lentes ainda precisamos recomendar para totalizar três lentes
        const remainingCount = 3 - indications.length;

        // Pega lentes de níveis subsequentes, limitando ao número de lentes restantes que precisamos recomendar
        const higherLevelLenses = availables
          .filter(
            (item) =>
              item.level > usedLevel &&
              item.fabricator === "7gUldOCYksy3Q7A5BUxD"
          )
          .sort((a, b) => a.level - b.level)
          .slice(0, remainingCount);

        // Adiciona as lentes subsequentes à lista de indicações
        indications = indications.concat(higherLevelLenses.map((item) => item.id));
      }
    }

    // Retorna as lentes recomendadas filtradas e ordenadas
    return designs
      .filter((item) => indications.includes(item.id))
      .sort((a, b) => a.level - b.level)
      .map((item) => ({ ...item }));
  }



  function getRefractionIndex() {
    const ri =
      designs.find((item) => item.id === switchedLens.design)
        ?.refractionIndexs ?? [];

    const options = refractionIndexs
      .map((item) =>
        ri.includes(item.name) ? item : ({} as BaseCategoriesType)
      )
      .filter((item) => item.id);

    const diopter = diopterCalculation(prescription);

    let selectedIndex = 0;

    if (frames === "Balgrif" && diopter <= 2) {
      selectedIndex = 1;
    } else {
      if (diopter >= 0 && diopter <= 2) {
        selectedIndex = 0;
      } else if (diopter <= 4) {
        selectedIndex = 1;
      } else if (diopter <= 6) {
        selectedIndex = 2;
      } else {
        selectedIndex = 3;
      }
    }

    while (!refractionIndexs[selectedIndex]) {
      selectedIndex--;
    }

    return {
      options: options.map((item, index) => {
        return {
          id: item.id,
          index: item.name,
          selected: index === selectedIndex,
        };
      }),
      comment:
        frames === "Balgrif"
          ? "As armações Balgrif devem ser montadas com índice mínimo de 1.59, pois esses resistem as furos."
          : "",
    };
  }

  function getAntireflectionOptions() {
    const options = [] as AntireflectionType[];
    let emptyOption = false;

    lenses
      .filter((item) => item.brand === switchedLens.brand)
      .filter((item) => item.design === switchedLens.design)
      .filter((item) => item.refractionIndex === switchedLens.refractionIndex)
      .filter((item) => item.technology === switchedLens.technology)
      .map((item) => {
        const antireflection = antireflections.find(
          (ar) => ar.id === item.antireflection
        );

        emptyOption = item.antireflection === "";

        if (
          antireflection &&
          !options.find((option) => option.id === antireflection.id)
        ) {
          options.push(antireflection);
        }

        return antireflection;
      });

    return {
      options,
      emptyOption,
    };
  }

  function getPhotosensitiveOptions() {
    const cil = Math.max(
      Math.abs(
        Number(prescription.leftEye.cil) ? Number(prescription.leftEye.cil) : 0
      ),
      Math.abs(
        Number(prescription.rightEye.cil)
          ? Number(prescription.rightEye.cil)
          : 0
      )
    );

    const options = [] as CategoryWithFabricator[];
    let emptyOption = false;

    lenses
      .filter((item) => item.brand === switchedLens.brand)
      .filter((item) => item.design === switchedLens.design)
      .filter((item) => item.refractionIndex === switchedLens.refractionIndex)
      .map((item) => {
        const technology = technologies.find((t) => t.id === item.technology);

        if (item.technology === "") {
          emptyOption = true;
        }

        if (
          technology &&
          !options.find((option) => option.id === technology.id)
        ) {
          options.push(technology);
        }

        return technology;
      });

    const valueCil = cil > 2 ? 2 : cil;

    const result = drive + outdoorActivity + photophobia + valueCil;
    let recommendation = "";

    if (result <= 2) {
      recommendation = "Uso opcional";
    } else if (result <= 4) {
      recommendation = "Recomendado";
    } else {
      recommendation = "Altamente recomendado";
    }

    return {
      recommendation,
      options,
      drive,
      emptyOption,
    };
  }

  function getIndicateSecondPair() {
    const result = { use: "", recommendation: 2 };

    const result1 = drive + outdoorActivity + photophobia;

    if (result1 <= 2) result.use = "opcional";
    else if (result1 <= 4) result.use = "recomendado";
    else if (result1 > 4) result.use = "altamente recomendado";

    if (Number(prescription.adition) > 0) {
      if (Number(shortActivityTime) < Number(interActivityTime)) {
        if (Number(interActivityTime) > 6) {
          result.recommendation = 1;
        }
      } else {
        if (Number(shortActivityTime) > 4) {
          result.recommendation = 0;
        }
      }
    }

    return result;
  }

  function getIndicateContactLenses() {
    if (
      useContactLenses > 0 ||
      (client &&
        birthDateForAge(client.birth) < 40 &&
        diopterCalculation(prescription))
    ) {
      return true;
    }

    return false;
  }

  function getLens() {
    const lens = lenses.find((item) => {
      return (
        item.design === switchedLens.design &&
        item.refractionIndex === switchedLens.refractionIndex &&
        item.technology === switchedLens.technology &&
        item.antireflection === switchedLens.antireflection
      );
    });

    return lens;
  }

  return (
    <QuestionaryContext.Provider
      value={{
        saveInStorage,
        clearStorage,
        visionProblem: () => visionProblems(prescription),
        getIndicateLenses,
        getRefractionIndex,
        getPhotosensitiveOptions,
        getAntireflectionOptions,
        getIndicateSecondPair,
        getIndicateContactLenses,
        getHighlights: () =>
          getHighlights(
            prescription,
            shortActivityTime,
            interActivityTime,
            mobileDeviceTime,
            desktopDeviceTime,
            fixedDeviceTime,
            outdoorActivity,
            photophobia
          ),
        getLens,
        prescription,
        setPrescription,
        frames,
        setFrames,
        useGlasses,
        setUseGlasses,
        glassesSelected,
        setGlassesSelected,
        shortActivityTime,
        setShortActivityTime,
        interActivityTime,
        setInterActivityTime,
        mobileDeviceTime,
        setMobileDeviceTime,
        desktopDeviceTime,
        setDesktopDeviceTime,
        fixedDeviceTime,
        setFixedDeviceTime,
        drive,
        setDrive,
        useContactLenses,
        setUseContactLenses,
        outdoorActivity,
        setOutdoorActivity,
        photophobia,
        setPhotophobia,
        switchedLens,
        setSwitchedLens,
        switchedContactLens,
        setSwitchedContactLens,
        switchedSecondPair,
        setSwitchedSecondPair,
      }}
    >
      {!isLoading && children}
    </QuestionaryContext.Provider>
  );
}
