import "./styles.css"

interface ModalEndOfQuestionaryProps {
  onClose: () => void
}

export default function ModalEndOfQuestionary({
  onClose,
}: ModalEndOfQuestionaryProps) {
  function next() {
    onClose()
  }

  return (
    <div
      className="modal-end-of-questionary-component
modal-end-of-questionary-component"
    >
      <div className="modal-content">
        <h2>Muito bom!</h2>

        <p>
          Você usa bastante a sua visão. Agora que nós conhecemos seus
          principais usos, poderemos te mostrar o que há de melhor para poder
          lhe propiciar mais saúde, conforto e aumento de produtividade no seu
          dia dia. Nos próximos passos vamos te guiar pelas características que
          você pode escolher das suas lentes com total transparência alinhando
          as suas necessidades com as suas possibilidades.
        </p>

        <button onClick={next} className="next-button">
          Vamos lá?
        </button>
      </div>
    </div>
  )
}
