import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import { ShopContext } from "../../../../contexts/shopContext";
import ListItem from "../../../../components/ListItems";

import "./styles.css";
import ModalDelete from "../../../../components/ModalDelete";

export default function RegisterShops() {
  const navigate = useNavigate();

  const { shops, createShop, editShop, deleteShop } = useContext(ShopContext);

  const [id, setId] = useState("");
  const [name, setName] = useState("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [itemEdit, setItemEdit] = useState<{ id: string; name: string }>();

  const [idToDelete, setIdToDelete] = useState<string>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    if (id.trim() === "") {
      setInputError(["Código não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (id.slice(0, 2) !== "MT" || !Number(id.slice(2, id.length))) {
      setInputError(["Código inválido"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      shops.filter((item) => item.name === name || item.id === id).length > 0
    ) {
      setInputError(["Loja já cadastrada"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createShop(name, id)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);

        setName("");
        setId("");
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    const name = itemEdit.name;
    const id = itemEdit.id;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      shops.filter((item) => item.name === name || item.id === id).length > 1
    ) {
      setInputError(["Loja já cadastrada"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editShop(name, id)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => {
          setRegisterSuccess(false);
          setItemEdit(undefined);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onDelete(event: FormEvent) {
    event.preventDefault();

    if (!idToDelete) {
      return;
    }

    setItemEdit(undefined);

    deleteShop(idToDelete)
      .then(() => {
        setIdToDelete(undefined);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-shop-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar loja</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Loja cadastrada com sucesso`} />
            )}

            <FormInput
              label="Código"
              placeholder="MT02"
              required
              value={id}
              onChange={(event) => setId(event.target.value)}
            />

            <FormInput
              label="Nome"
              placeholder="São Vicente"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar loja</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Loja editada com sucesso`} />
            )}

            <FormInput label="Código" placeholder="MT02" value={itemEdit.id} />

            <FormInput
              label="Nome"
              placeholder="São Vicente"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        {idToDelete && (
          <ModalDelete
            nameItem={shops.find((item) => item.id === idToDelete)?.name ?? ""}
            onDelete={onDelete}
            onCancel={() => setIdToDelete(undefined)}
          />
        )}

        <ListItem
          render={shops.map((item) => {
            return {
              item: [item.id, item.name],
              onEdit: () => {
                setItemEdit(item);
              },
              onDelete: () => {
                setIdToDelete(item.id);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
