import React from 'react';

import './styles.css';

interface MenuInterface {
  // eslint-disable-next-line
  list: ItemMenu[];
}

interface ItemMenu {
  label: string;
  onClick: (event: React.MouseEvent) => void;
}

const MenuConsultant: React.FC<MenuInterface> = ({ list }) => {
  return (
    <div className="menu-consultant">
      {list.map((item) => (
        <div className="item" key={item.label} onClick={item.onClick}>
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default MenuConsultant;
