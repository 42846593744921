import { createContext, ReactNode, useEffect, useState } from "react";
import ClientType from "../types/Client";
import { addDocument, getAllDocuments, getDocumentById, getDocumentByParam, updateDocument } from "../controller/firebaseController";

interface ClientContextData {
  clients: ClientType[];
  getClient: (cpf: string) => Promise<ClientType | undefined>;
  createClient: (client: ClientType) => Promise<void>;
  editClient: (client: ClientType) => Promise<void>;
  client?: ClientType;
  setClient: (client: ClientType) => void;
}

interface ClientProviderProps {
  children: ReactNode;
}

export const ClientContext = createContext({} as ClientContextData);

export function ClientProvider({ children }: ClientProviderProps) {
  const [clientInQuestionary, setClientInQuestionary] = useState<ClientType>();
  const [clients, setClients] = useState<ClientType[]>([]);
  const [isLoading] = useState(false);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    async function getClientById(clientId: string) {
      const res = await getDocumentById("clients", clientId);

      const data = res.data() as ClientType;
      setClientInQuestionary({
        ...data,
        id: storageClientId,
      } as ClientType);
    }

    const storageClientId = sessionStorage.getItem("client");

    if (storageClientId) {
      getClientById(storageClientId)
    }

  }, []);

  useEffect(() => {
    if (clientInQuestionary) {
      sessionStorage.setItem("client", clientInQuestionary.id);
    }
  }, [clientInQuestionary]);

  async function getClients() {
    const res = await getAllDocuments("clients")

    setClients(
      res.docs.map((item) => {
        const data = item.data() as ClientType;
        return { ...data, id: item.id };
      })
    );
  }

  function setClient(client: ClientType) {
    setClientInQuestionary(client);
  }


  async function getClient(cpf: string) {
    const response = await getDocumentByParam("clients", "cpf", cpf)

    if (response.docs.length > 0) {
      const data = response.docs[0].data() as ClientType;
      return { ...data, id: response.docs[0].id } as ClientType;
    }

    return undefined;
  }

  async function createClient(client: ClientType) {
    await addDocument("clients", client)
  }

  async function editClient(client: ClientType) {
    await updateDocument("clients", client.id, client)
  }

  return (
    <ClientContext.Provider
      value={{
        clients,
        getClient,
        createClient,
        editClient,
        client: clientInQuestionary,
        setClient,
      }}
    >
      {!isLoading && children}
    </ClientContext.Provider>
  );
}
