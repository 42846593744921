import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "../services/firebase";

export async function getAllDocuments(colletionName: string, orderByParam?: string, orderDirection: "asc" | "desc" = "desc") {
  const docCollection = collection(db, colletionName);

  if (orderByParam) {
    const queryParam = query(docCollection, orderBy(orderByParam, orderDirection))
    return await getDocs(queryParam);
  }

  return await getDocs(docCollection);
}

export async function getDocumentById(colletionName: string, docId: string) {
  const docRef = doc(db, colletionName, docId);

  return await getDoc(docRef);
}

export async function getDocumentByParam(colletionName: string, param: string, value: unknown, orderByParam?: string, orderDirection: "asc" | "desc" = "asc") {
  const docCollection = collection(db, colletionName);
  let queryParam;

  if (orderByParam) {
    queryParam = query(docCollection, where(param, "==", value), orderBy(orderByParam, orderDirection))
  }

  queryParam = query(docCollection, where(param, "==", value));

  return await getDocs(queryParam);
}

export async function addDocument(colletionName: string, data: unknown) {
  const docCollection = collection(db, colletionName);

  return await addDoc(docCollection, data);
}

export async function updateDocument(colletionName: string, docId: string, data: unknown) {
  const docCollection = collection(db, colletionName);

  return await setDoc(doc(docCollection, docId), data);
}

export async function deleteDocument(colletionName: string, docId: string) {
  const docRef = doc(db, colletionName, docId);
  return await deleteDoc(docRef)
}