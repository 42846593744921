import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ContentQuestionary from "../../../components/ContentQuestionary";
import ButtonRadioSelect from "../../../components/ButtonRadioSelect";
import { QuestionaryContext } from "../../../contexts/questionaryContext";

import questionaryImage from "../../../assets/other-info.jpg";

import "./styles.css";
import PageModel from "../../../components/PageModel";

export default function OthersInfoPage() {
  const navigate = useNavigate();

  const {
    drive,
    setDrive,
    useContactLenses,
    setUseContactLenses,
    outdoorActivity,
    setOutdoorActivity,
    photophobia,
    setPhotophobia,
  } = useContext(QuestionaryContext);

  const [validationQ7, setValidationQ7] = useState(true);
  const [validationQ8, setValidationQ8] = useState(true);
  const [validationQ9, setValidationQ9] = useState(true);
  const [validationQ10, setValidationQ10] = useState(true);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);

  function next() {
    if (drive === -1 || useContactLenses === -1) {
      setValidationQ7(drive !== -1);
      setValidationQ8(useContactLenses !== -1);

      setTimeout(() => {
        setValidationQ7(true);
        setValidationQ8(true);
      }, 1500);
      return;
    }

    if (outdoorActivity === -1 || photophobia === -1) {
      setValidationQ9(outdoorActivity !== -1);
      setValidationQ10(photophobia !== -1);

      setTimeout(() => {
        setValidationQ9(true);
        setValidationQ10(true);
      }, 1500);
      return;
    }
    navigate("/design");
  }

  function previous() {
    navigate("/questionary/vision-use");
  }

  if (count1 !== 0 && count2 !== 0 && count3 !== 0 && count4 !== 0) {
    next();
  }

  return (
    <PageModel
      title="Trânsito e lentes de contato"
      previous={previous}
      next={next}
      number={4}
    >
      <ContentQuestionary srcImage={questionaryImage}>
        <div className="question-box">
          <h4>
            <strong className={validationQ7 ? "" : "error"}>
              Você dirige ou pilota?
            </strong>
          </h4>

          <div className="options">
            <ButtonRadioSelect
              label="Nunca"
              isSelected={drive === 0}
              setSelect={() => {
                setDrive(0);
                setCount1(count1 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Às vezes"
              isSelected={drive === 1}
              setSelect={() => {
                setDrive(1);
                setCount1(count1 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Sempre"
              isSelected={drive === 2}
              setSelect={() => {
                setDrive(2);
                setCount1(count1 + 1);
              }}
            />
          </div>
        </div>

        <div className="question-box">
          <h4>
            <strong className={validationQ8 ? "" : "error"}>
              Você usa lentes de contato?
            </strong>
          </h4>

          <div className="options">
            <ButtonRadioSelect
              label="Nunca"
              isSelected={useContactLenses === 0}
              setSelect={() => {
                setUseContactLenses(0);
                setCount2(count2 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Às vezes"
              isSelected={useContactLenses === 1}
              setSelect={() => {
                setUseContactLenses(1);
                setCount2(count2 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Sempre"
              isSelected={useContactLenses === 2}
              setSelect={() => {
                setUseContactLenses(2);
                setCount2(count2 + 1);
              }}
            />
          </div>
        </div>
        <div className="question-box">
          <h4>
            <strong className={validationQ9 ? "" : "error"}>
              Você costuma fazer atividades ao ar livre?
            </strong>
          </h4>

          <div className="options">
            <ButtonRadioSelect
              label="Nunca"
              isSelected={outdoorActivity === 0}
              setSelect={() => {
                setOutdoorActivity(0);
                setCount3(count3 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Às vezes"
              isSelected={outdoorActivity === 1}
              setSelect={() => {
                setOutdoorActivity(1);
                setCount3(count3 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Regularmente"
              isSelected={outdoorActivity === 2}
              setSelect={() => {
                setOutdoorActivity(2);
                setCount3(count3 + 1);
              }}
            />
          </div>
        </div>

        <div className="question-box">
          <h4>
            <strong className={validationQ10 ? "" : "error"}>
              Você se sente incomodado com a luz ou brilho?
            </strong>
          </h4>

          <div className="options">
            <ButtonRadioSelect
              label="Nunca"
              isSelected={photophobia === 0}
              setSelect={() => {
                setPhotophobia(0);
                setCount4(count4 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Às vezes"
              isSelected={photophobia === 1}
              setSelect={() => {
                setPhotophobia(1);
                setCount4(count4 + 1);
              }}
            />
            <ButtonRadioSelect
              label="Regularmente"
              isSelected={photophobia === 2}
              setSelect={() => {
                setPhotophobia(2);
                setCount4(count4 + 1);
              }}
            />
          </div>
        </div>
      </ContentQuestionary>
    </PageModel>
  );
}
