import { useState, FormEvent, useContext } from "react"
import Header from "../../../../components/Header"
import { useNavigate } from "react-router-dom"
import { BsArrowLeftShort } from "react-icons/bs"
import { ConsultantContext } from "../../../../contexts/consultantContext"
import Loading from "../../../../components/Loading"
import FormButton from "../../../../components/FormButton"
import FormInput from "../../../../components/FormInput"
import FormSelect from "../../../../components/FormSelect"
import { ShopContext } from "../../../../contexts/shopContext"
import ListItem from "../../../../components/ListItems"
import BoxError from "../../../../components/BoxError"
import BoxSuccess from "../../../../components/BoxSuccess"

import "./styles.css"

export default function AdminConsultant() {
  // Variável do react-router-dom para manipular as rotas
  const navigate = useNavigate()

  const { createConsultant, editConsultant, deleteConsultant, consultants } =
    useContext(ConsultantContext)
  const { shops } = useContext(ShopContext)

  // Valores dos inputs do formulário de cadastro
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [shop, setShop] = useState("") // Alterado de "0" para ""
  const [password, setPassword] = useState("")

  const [registerSuccess, setRegisterSuccess] = useState(false)

  // Dados de erros de cada input do formulário de cadastro
  const [inputError, setInputError] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const [itemEdit, setItemEdit] = useState<{
    id: string
    email: string
    name: string
    shop: string
    newPassword?: string
  }>()

  // Domínios válidos
  const validDomains = [
    "@oticasmercadotica.com.br",
    "@oticasmercadotica.com",
    "@mercadotica.com",
  ]

  function onRegister(e: FormEvent) {
    // Evita que a página recarregue ao clicar no botão submit
    e.preventDefault()

    if (isLoading) {
      return
    }

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (email.trim() === "") {
      setInputError(["Email não pode ser vazio"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (!validDomains.some((domain) => email.endsWith(domain))) {
      setInputError(["Email deve ter domínio das oticas mercadotica"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (!shop || shop.trim() === "") {
      setInputError(["Consultor deve conter uma loja"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (password === "" || password.length < 6) {
      setInputError(["Senha inválida"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    setIsLoading(true)

    createConsultant(name, email, shop, password)
      .then(() => {
        console.log("Consultor cadastrado com sucesso")
        setRegisterSuccess(true)
        setTimeout(() => {
          setRegisterSuccess(false)
          // Limpa os dados do cadastro
          setName("")
          setEmail("")
          setShop("") // Limpar a loja após cadastro
          setPassword("")
        }, 2000)
      })
      .catch((err) => {
        console.error("Erro ao cadastrar consultor:", err)
        setInputError(["Erro: " + err])
      })
      .finally(() => {
        setIsLoading(false)
        setTimeout(() => setInputError([]), 2000)
      })
  }

  function onEdit(e: FormEvent) {
    // Evita que a página recarregue ao clicar no botão submit
    e.preventDefault()

    if (isLoading || !itemEdit) {
      return
    }

    const id = itemEdit.id
    const email = itemEdit.email
    const name = itemEdit.name
    const shop = itemEdit.shop
    const password =
      itemEdit.newPassword?.trim() === "" ? undefined : itemEdit.newPassword

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (email.trim() === "") {
      setInputError(["Email não pode ser vazio"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (!validDomains.some((domain) => email.endsWith(domain))) {
      setInputError(["Email deve ter domínio das oticas mercadotica"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    if (!shop || shop.trim() === "") {
      setInputError(["Consultor deve conter uma loja"])
      setTimeout(() => setInputError([]), 2000)
      return
    }

    setIsLoading(true)

    editConsultant(id, name, email, shop, password)
      .then(() => {
        console.log("Consultor editado com sucesso")
        setRegisterSuccess(true)
        setTimeout(() => {
          setRegisterSuccess(false)
          setItemEdit(undefined)
        }, 2000)
      })
      .catch((err) => {
        console.error("Erro ao editar consultor:", err)
        setInputError(["Erro: " + err])
        setTimeout(() => setInputError([]), 2000)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function onDelete() {
    if (isLoading || !itemEdit) {
      return
    }

    const id = itemEdit.id

    deleteConsultant(id)
      .then(() => {
        console.log("Consultor excluído com sucesso")
        setRegisterSuccess(true)
        setTimeout(() => {
          setRegisterSuccess(false)
          setItemEdit(undefined)
        }, 2000)
      })
      .catch((err) => {
        console.error("Erro ao excluir consultor:", err)
        setInputError(["Erro: " + err])
        setTimeout(() => setInputError([]), 2000)
      })
  }

  // Voltar para tela principal
  function goBack() {
    navigate(-1)
  }

  return (
    <div className="admin-consultant">
      <Header>
        <div className="actions-header">
          <button
            className="go-back-button"
            onClick={() => goBack()}
            title="Voltar"
          >
            <BsArrowLeftShort className="icon" size={30} />
          </button>
        </div>
      </Header>

      <main>
        {!itemEdit ? (
          <form onSubmit={onRegister} autoComplete="off">
            <h2>Cadastrar novo consultor</h2>

            {registerSuccess && <BoxSuccess message={`Criado com sucesso`} />}

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            <FormInput
              label="Nome"
              type="text"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormInput
              label="Email"
              value={email}
              autoComplete="off"
              required
              onChange={(event) => setEmail(event.target.value)}
            />

            <FormSelect
              label="Loja"
              required
              options={shops.map((item) => {
                return { value: item.id, label: `${item.id} - ${item.name}` }
              })}
              value={shop}
              onChange={(value) => value && setShop(value.value)}
            />

            <FormInput
              label="Senha"
              type="password"
              autoComplete="off"
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />

            <FormButton
              type="submit"
              children={isLoading ? <Loading transparency size={25} /> : "Cadastrar"}
            />
          </form>
        ) : (
          <form onSubmit={onEdit} autoComplete="off">
            <h2>Editar consultor</h2>

            {registerSuccess && <BoxSuccess message={`Editado com sucesso`} />}

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            <FormInput
              label="Nome"
              type="text"
              value={itemEdit.name}
              required
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormInput
              label="Email"
              type="email"
              value={itemEdit.email}
              required
              onChange={(event) =>
                setItemEdit({ ...itemEdit, email: event.target.value })
              }
            />

            <FormSelect
              label="Loja"
              required
              value={itemEdit.shop ?? ""}
              options={shops.map((item) => {
                return { value: item.id, label: `${item.id} - ${item.name}` }
              })}
              onChange={(value) =>
                value && setItemEdit({ ...itemEdit, shop: value.value })
              }
            />

            <FormInput
              label="Nova senha"
              type="password"
              value={itemEdit.newPassword}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, newPassword: event.target.value })
              }
            />

            <FormButton type="submit" isLoading={isLoading}>
              Editar
            </FormButton>

            <FormButton
              type="button"
              onClick={() => setItemEdit(undefined)}
              isLoading={isLoading}
            >
              Cancelar
            </FormButton>

            <FormButton
              type="button"
              onClick={() => onDelete()}
              isLoading={isLoading}
            >
              Excluir
            </FormButton>
          </form>
        )}

        <ListItem
          render={consultants.map((item) => {
            return {
              item: [item.shop ?? "ADMIN", item.name, item.email],
              onEdit: () => {
                setItemEdit(item)
              },
            }
          })}
        />
      </main>
    </div>
  )
}
