import { FormEvent, useState, useContext } from "react"
import DatePicker from "../../components/DatePicker"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import Client from "../../types/Client"
import InputMask from "react-input-mask"
import { ClientContext } from "../../contexts/clientContext"
import MenuConsultant from "../../components/MenuConsultant"
import SearchInput from "../../components/SearchInput"
import { LoginContext } from "../../contexts/loginContext"

import birthDateForAge from "../../utils/birthDateForAge"
import verifyCPF from "../../utils/verifyCPF"
import formatCpf from "../../utils/formatCpf"
import verifyPhone from "../../utils/verifyPhone"
import removerCharsCpf from "../../utils/removeCharsCpf"

import "./styles.css"

export default function Home() {
  //Variavel do react-router-dom para manipular as rotas
  const navigate = useNavigate()

  const { currentUser, logout } = useContext(LoginContext)
  const { getClient, setClient, createClient } = useContext(ClientContext)

  //Valor do input de pesquisa de cliente
  const [cpfSearch, setCpfSearch] = useState("")
  //Dados do cliente encontrado
  const [clientSearched, setClientSearched] = useState<Client>()

  //Valores dos inputs do formulario de cadastro
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [cpf, setCpf] = useState("")
  const [gender, setGender] = useState("")
  const [day, setDay] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")

  //Dados de erros de cada input do formulario de cadastro
  const [nameInputError, setNameInputError] = useState(false)
  const [emailInputError, setEmailInputError] = useState(false)
  const [phoneInputError, setPhoneInputError] = useState(false)
  const [cpfInputError, setCpfInputError] = useState(false)
  const [genderInputError, setGenderInputError] = useState(false)
  const [dayInputError, setDayInputError] = useState(false)
  const [monthInputError, setMonthInputError] = useState(false)
  const [yearInputError, setYearInputError] = useState(false)

  //Parametro de carregamento quando realiza uma pesquisa de cliente
  const [loadingSearch, setLoadingSearch] = useState(false)
  //Parametro para mostrar o menu de opcoes do consultor
  const [showMenu, setShowMenu] = useState(false)

  //Realiza a busca de cliente pelo CPF digitado
  function searchClient(e: FormEvent) {
    //Evita que a página recarregue ao clicar no botao submit
    e.preventDefault()

    if (loadingSearch) {
      return
    }

    //limpa os dados de um possivel cliente pesquisado antes
    setClientSearched({
      id: "",
      name: "",
      cpf: "",
      email: "",
      gender: "feminino",
      phone: "",
      birth: "",
    })

    if (cpfSearch === "") {
      return
    }

    const cpf = cpfSearch
      .split("")
      .filter((item) => Number(item))
      .join("")

    //Indica inicio do carregamento
    setLoadingSearch(true)

    getClient(cpf)
      .then((res) => {
        if (res) {
          setClientSearched(res)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoadingSearch(false))
  }

  //Tenta realizar o cadastro de um cliente
  function registerClient(e: FormEvent) {
    //Evita que a página recarregue ao clicar no botao submit
    e.preventDefault()

    if (loadingSearch) {
      return
    }

    setLoadingSearch(true)

    const cpfNumbers = removerCharsCpf(cpf)
    //Verificar os campos vazios e armazenar no state
    setNameInputError(name.trimEnd().trimStart() === "")
    setPhoneInputError(!verifyPhone(phone))
    setCpfInputError(cpfNumbers === "" || !verifyCPF(cpfNumbers))
    setGenderInputError(gender === "")
    setDayInputError(day === "")
    setMonthInputError(month === "")
    setYearInputError(year === "")

    //Se tiver algum campo vazio, saia da funcao
    if (
      name.trimEnd().trimStart() === "" ||
      !verifyPhone(phone) ||
      cpfNumbers === "" ||
      !verifyCPF(cpfNumbers) ||
      gender === "" ||
      day === "" ||
      month === "" ||
      year === ""
    ) {
      setLoadingSearch(false)
      return
    }

    if (!verifyCPF(cpfNumbers)) {
      setLoadingSearch(false)
      alert("CPF inválido")
      return
    }

    createClient({
      name,
      email,
      cpf: cpf
        .split("")
        .filter((item) => Number(item))
        .join(""),
      gender,
      birth: `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`,
      phone,
    } as Client)
      .then(() => {
        alert("Cliente cadastrado com sucesso")
        setName("")
        setGender("")
        setEmail("")
        setPhone("")
        setCpf("")
        setDay("")
        setMonth("")
        setYear("")
        setCpfSearch(cpf)
      })
      .catch((err) => {
        console.log(err)
      })

    setLoadingSearch(false)
  }

  //Vá para a tela de edicao dos dados de um cliente
  function editClientData() {
    if (loadingSearch) {
      return
    }

    navigate("edit/client", { state: clientSearched })
  }

  //Vá para a tela do questionario
  function newResponse() {
    if (loadingSearch) {
      return
    }

    if (clientSearched && clientSearched.name.trim() !== "") {
      setClient(clientSearched)
      navigate("/questionary/prescription")
    }
  }

  function onViewReport() {
    navigate("/consultant/reports")
  }

  //Fazer logout do consultor
  async function onLogout() {
    if (loadingSearch) {
      return
    }

    await logout().finally(() => {
      navigate("/login")
    })
  }

  return (
    <div className="home-page">
      <Header>
        <div className="actions-header">
          <button
            className="profile-data"
            onClick={() => setShowMenu(!showMenu)}
          >
            {showMenu ? (
              <strong className="options">
                <span>Opções</span>
                <span>X</span>
              </strong>
            ) : (
              <>
                <strong>
                  {currentUser && currentUser.name
                    ? currentUser.name
                    : "Carregando..."}
                </strong>
                <label>
                  {currentUser && currentUser.shop
                    ? `Loja ${currentUser.shop}`
                    : ""}
                </label>
              </>
            )}
          </button>
          {showMenu && (
            <MenuConsultant
              list={[
                { label: "Meus relatórios", onClick: onViewReport },
                { label: "Sair", onClick: onLogout },
              ]}
            />
          )}
        </div>
      </Header>

      <main>
        <div className="new-response-box">
          <h3>Nova resposta</h3>

          <form onSubmit={searchClient}>
            <label>Cliente</label>
            <SearchInput
              value={cpfSearch}
              setValue={(event) => setCpfSearch(event.target.value)}
              placeholder="CPF"
              mask="999.999.999-99"
            />

            {!clientSearched ? null : clientSearched &&
              clientSearched.name === "" ? (
              <div className="client-searched">
                <label>
                  {loadingSearch
                    ? "Carregando..."
                    : "Nenhum resultado para a busca"}
                </label>
              </div>
            ) : (
              <div className="client-searched">
                <div>
                  <strong>Nome: </strong>
                  <label>{clientSearched && clientSearched.name}</label>
                </div>
                <div>
                  <strong>Email: </strong>
                  <label>{clientSearched && clientSearched.email}</label>
                </div>
                <div>
                  <strong>Whatsapp: </strong>
                  <label>{clientSearched && clientSearched.phone}</label>
                </div>
                <div>
                  <strong>Sexo: </strong>
                  <label>{clientSearched && clientSearched.gender}</label>
                </div>
                <div>
                  <strong>Idade: </strong>
                  <label>
                    {clientSearched &&
                      birthDateForAge(String(clientSearched.birth))}{" "}
                    anos
                  </label>
                </div>
                <div className="actions-client">
                  <button
                    className="edit-data-button"
                    onClick={() => editClientData()}
                  >
                    Editar dados
                  </button>
                  <button
                    className="new-response-button"
                    onClick={() => newResponse()}
                  >
                    Nova pesquisa
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>

        <div className="separator" />

        <div className="register-box">
          <h3>Cadastrar novo cliente</h3>

          <form onSubmit={registerClient}>
            <div className="input-box name">
              <label>Nome</label>
              <input
                type="text"
                className={nameInputError ? "input-error" : ""}
                value={name}
                onChange={(event) => setName(event.target.value)}
                onFocus={() => setNameInputError(false)}
              />
            </div>

            <div className="input-box gender">
              <label>Sexo</label>
              <select
                className={genderInputError ? "input-error" : ""}
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                onFocus={() => setGenderInputError(false)}
              >
                <option value="none" hidden>
                  Selecione
                </option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
              </select>
            </div>

            <div className="input-box email">
              <label>Email</label>
              <input
                type="email"
                className={emailInputError ? "input-error" : ""}
                value={email}
                onChange={(event) => setEmail(event.target.value.trim())}
                onFocus={() => setEmailInputError(false)}
              />
            </div>

            <div className="input-box phone">
              <label>Whatsapp</label>
              <InputMask
                mask="(99) 99999-9999"
                type="tel"
                className={phoneInputError ? "input-error" : ""}
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                onFocus={() => setPhoneInputError(false)}
              />
            </div>

            <div className="input-box cpf">
              <label>CPF</label>
              <InputMask
                mask="999.999.999-99"
                type="text"
                className={cpfInputError ? "input-error" : ""}
                value={cpf}
                onChange={(event) => setCpf(formatCpf(event.target.value))}
                onFocus={() => setCpfInputError(false)}
              />
            </div>

            <div className="input-box birth">
              <label>Data de Nascimento</label>
              <DatePicker
                day={day}
                month={month}
                year={year}
                setDay={setDay}
                setMonth={setMonth}
                setYear={setYear}
                dayInputError={dayInputError}
                monthInputError={monthInputError}
                yearInputError={yearInputError}
                setDayInputError={setDayInputError}
                setMonthInputError={setMonthInputError}
                setYearInputError={setYearInputError}
              />
            </div>

            <button className="button-register" type="submit">
              Cadastrar
            </button>
          </form>
        </div>
      </main>
    </div>
  )
}
