import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import PageModel from "../../../components/PageModel";
import "../DesignPage";

import imageGroup1 from "../../../assets/index-group1.png";
import imageGroup2 from "../../../assets/index-group2.png";
import imageGroup3 from "../../../assets/index-group3.png";
import imageGroup4 from "../../../assets/index-group4.png";

import "./styles.css";
import ListItem from "../../../components/ListItems";

export default function RefractionPage() {
  const navigate = useNavigate();

  const { getRefractionIndex, setSwitchedLens, switchedLens, saveInStorage, glassesSelected } =
    useContext(QuestionaryContext);

  const [allIndex, setAllIndex] = useState<
    { id: string; index: string; selected?: boolean }[]
  >([]);
  const [indicateIndex, setIndicateIndex] = useState<any | undefined>();
  const [selected, setSelected] = useState<number | undefined>();
  const [comment, setComment] = useState("");

  useEffect(() => {
  
      const refractionIndex = getRefractionIndex();

      setIndicateIndex(
        refractionIndex.options.find((item, index) => {
          setSelected(index);
          return item.selected;
        })
      );
      setAllIndex(refractionIndex.options);
      setComment(refractionIndex.comment);
    
  }, [glassesSelected, switchedLens, getRefractionIndex]);

  function selectIndex(position: number) {
    setSelected(position);
  }

  function next() {
    if (selected === undefined) {
      return;
    }

    const switchedIndex = allIndex[selected];

    if (switchedIndex) {
      setSwitchedLens({
        ...switchedLens,
        refractionIndex: switchedIndex.id,
      });

      saveInStorage(8);

      navigate("/technology");
    }
  }

  function previous() {
    navigate("/design");
    saveInStorage(6);
  }

  return (
    <PageModel
      previous={previous}
      next={selected === undefined ? undefined : next}
      title="Índice de refração"
      number={6}
    >
      <div className="refraction-content">
        <h3>{`Selecione o índice de refração. O mínimo indicado para você é ${indicateIndex && indicateIndex.index}`}</h3>

        {comment !== "" && <h4>{comment}</h4>}

        <p className="observation">
          Expessura das lentes nas imagens baseadas no grau +4.00, 1.00 e -4.00,
          respectivamente
        </p>

        <div className="options">
          {allIndex && allIndex[0] && (
            <button
              onClick={() => selectIndex(0)}
              className={selected === 0 ? "option selected" : "option"}
            >
              <img
                className="index-image"
                src={imageGroup1}
                alt="Índice de refração do grupo 1"
              />
              <div>
                <p>{allIndex[0].index}</p>
              </div>
              <p className="description">
                <p>Resina comum (índice 1.50)</p>
                <span className="separator" />
                <p>97% UVA e 100% UVB</p>
              </p>
            </button>
          )}
          {allIndex && allIndex[1] && (
            <button
              onClick={() => selectIndex(1)}
              className={selected === 1 ? "option selected" : "option"}
            >
              <img
                className="index-image"
                src={imageGroup2}
                alt="Índice de refração do grupo 2"
              />
              <div>
                <p>{allIndex[1].index}</p>
              </div>
              <p className="description">
                <p>20% mais finas que 1.50</p>
                <span className="separator" />
                <p>30% mais leves</p>
                <span className="separator" />
                <p>12x mais resistentes a quebra</p>
                <span className="separator" />
                <p>100% de UVA e UVB</p>
              </p>
            </button>
          )}
          {allIndex && allIndex[2] && (
            <button
              onClick={() => selectIndex(2)}
              className={selected === 2 ? "option selected" : "option"}
            >
              <img
                className="index-image"
                src={imageGroup3}
                alt="Índice de refração do grupo 3"
              />
              <div>
                <p>{allIndex[2].index}</p>
              </div>
              <p className="description">
                <p>Alto Indice 1.67</p>
                <span className="separator" />
                <p>30% mais finas que 1.50</p>
                <span className="separator" />
                <p>100% de UVA e UVB</p>
                <span className="separator" />
                <p>30% mais leves</p>
              </p>
            </button>
          )}
          {allIndex && allIndex[3] && (
            <button
              onClick={() => selectIndex(3)}
              className={selected === 3 ? "option selected" : "option"}
            >
              <img
                className="index-image"
                src={imageGroup4}
                alt="Índice de refração do grupo 4"
              />
              <div>
                <p>{allIndex[3].index}</p>
              </div>
              <p className="description">
                <p>Alto Indice 1.74</p>
                <span className="separator" />
                <p>35% mais finas que 1.50</p>
                <span className="separator" />
                <p>100% de UVA e UVB</p>
              </p>
            </button>
          )}
        </div>
      </div>
    </PageModel>
  );
}
