import { useContext, useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/Header";
import { ListItemsCustom, SelectShopObjectType } from "../../../../components/ListItemsCustom";
import Loading from "../../../../components/Loading";
import MonitorItem from "../../../../components/MonitorItem";
import { ResponseContext } from "../../../../contexts/responseContext";
import { ShopContext } from "../../../../contexts/shopContext";
import { getTotalClients } from "../../../../utils/getTotalClients";

import './styles.css'

export default function Monitoring() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [arroba, setArroba] = useState<SelectShopObjectType[]>([]);
  
  const { shops } = useContext(ShopContext);
  const { getMetrics, metrics } = useContext(ResponseContext);
  
  //Voltar para tela principal
    function goBack() {
      navigate(-1)
    }

    const onSelectShop = (shop: string) => {
      console.log(shop)
      getMetrics(shop)
    }

    useEffect(() => {
      const teste = async () => {
        setLoading(true);
        const newArray: SelectShopObjectType[] = [];
        for(let i= 0; i < shops.length; i++) {
          const shop = shops[i];
          await getTotalClients(shop.id).then((total) => {
            console.log(`loja: ${shop.id} total: ${total}`)
            newArray.push({
              shop,
              total
            });
          });
        }
        setArroba(newArray);
        setLoading(false);
      }
      teste();
    }, [shops])
    
  return (
    <>
    <Header>
        <div className="actions-header">
          <button
            className="go-back-button"
            onClick={() => goBack()}
            title="Voltar"
          >
            <BsArrowLeftShort className="icon" size={30} />
          </button>
        </div>
      </Header> 
      {loading ? <Loading transparency size={50} /> : <div className="Monitoring">
    

      <main>
        <div className="monitor">
          <h1>Monitoramento</h1>
          <MonitorItem items={metrics} />
        </div>
        <div className="separator" />
        <div className="shopList">
          <h2>Lista de lojas</h2>
          <div className="content">
          {
            arroba.map((item, index) => {
              return <ListItemsCustom objectProp={item} onSelectShop={() => onSelectShop(item.shop.id)}  key={index} />
            })
          }
          </div>
        </div>
      </main>
      </div>}
      
    </>
  )
}

