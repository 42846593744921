import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentQuestionary from "../../../components/ContentQuestionary";
import PageModel from "../../../components/PageModel";
import { ClientContext } from "../../../contexts/clientContext";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import VisionProblem from "../../../types/VisionProblem";

import emetropiaImage from "../../../assets/emetropia.jpeg";
import hipermetropiaImage from "../../../assets/hipermetropia.jpeg";
import astigmatismoImage from "../../../assets/astigmatismo.jpeg";
import miopiaImage from "../../../assets/miopia.jpeg";
import presbiopiaImage from "../../../assets/presbiopia.jpeg";

import "./styles.css";

let timeout: NodeJS.Timeout;

const problemsItem = [
  {
    name: "Emetropia",
    image: emetropiaImage,
    text: "Ou seja, sua visão está excelente",
  },
  {
    name: "Hipermetropia",
    image: hipermetropiaImage,
    text: "Também definida como uma leve irregularidade no formato interno dos olhos, a hipermetropia é uma condição na qual o olho geralmente possui tamanho menor do que o considerado normal. Pessoas com esse problema apresentam dificuldade de enxergar objetos de perto, pois o cristalino não consegue focalizar na retina imagens muito próximas. Algumas vezes o hipermetrope enxerga de perto com nitidez mas sente dores de cabeça ou até náusea,isso porque o olho está fazendo mais esforço do que deveria por estar sem a correção adequada.",
  },
  {
    name: "Miopia",
    image: miopiaImage,
    text: "Você já observou que algumas pessoas que utilizam óculos costumam tirar o acessório quando vão ler um livro ou enxergar algo mais de perto? O gesto pode parecer estranho, mas faz todo sentido quando entendemos a realidade da miopia. Em geral, indivíduos míopes conseguem enxergar bem (sem uso de óculos) objetos que estão perto, porém têm dificuldade em ver de longe. O grau de miopia tende a aumentar durante o período de crescimento e ter menor variação na fase adulta.",
  },
  {
    name: "Presbiopia",
    image: presbiopiaImage,
    text: "Condição mais comum após os 40 anos, a presbiopia é conhecida pela maioria das pessoas como vista cansada e consiste na dificuldade de enxergar principalmente de perto. Em cada um desses casos, há variações nos graus de dificuldade que, consequentemente, irão refletir no tipo de tratamento indicado pelo médico oftalmologista. A escolha do tratamento para corrigir os erros de refração – entre as opções de óculos, lentes e cirurgia – deve ser feita considerando a avaliação do especialista e o conforto e bem-estar do paciente.",
  },
  {
    name: "Astigmatismo",
    image: astigmatismoImage,
    text: "O astigmatismo consiste em um defeito na curvatura da córnea, que faz com que o paciente enxergue a imagem em planos diferentes, deixando-a distorcida. Uma pessoa sem astigmatismo tem a córnea com uma curvatura simétrica. Em quem tem astigmatismo, esse formato é assimétrico, em menor ou maior grau.",
  },
] as VisionProblem[];

export default function PrescriptionResumePage() {
  const navigate = useNavigate();

  const { visionProblem } = useContext(QuestionaryContext);

  const { client } = useContext(ClientContext);

  const [textSlice, setTextSlice] = useState("");

  const [problems, setProblems] = useState<(VisionProblem | undefined)[]>([]);

  const [isShowProblems, setIsShowProblems] = useState(false);

  const title = `Olá, ${
    client?.name.split(" ")[0]
  }! Essa é a análise da sua visão`;

  useEffect(() => {
    const problems = visionProblem();

    setProblems(
      problems.map((item) =>
        problemsItem.find((problem) => problem.name === item)
      )
    );
  }, [visionProblem]);

  useEffect(() => {
    const showSlowlyMsg = sessionStorage.getItem("show-slowly");

    if (showSlowlyMsg === "no") {
      setTextSlice(title);
      return;
    }
  }, [title]);

  useEffect(() => {
    clearTimeout(timeout);

    if (textSlice.length === title.length) {
      setIsShowProblems(true);
      sessionStorage.setItem("show-slowly", "no");
      return;
    }

    timeout = setTimeout(() => {
      setTextSlice(title.slice(0, textSlice.length + 1));
    }, 60);
    // eslint-disable-next-line
  }, [textSlice]);

  function previous() {
    navigate("/questionary/prescription");
  }

  function next() {
    navigate("/questionary/vision-use");
  }

  return (
    <PageModel
      title="Resumo da receita"
      previous={previous}
      next={next}
      number={2}
    >
      <ContentQuestionary>
        <div className="prescription-resume-content">
          <h2>{textSlice}</h2>

          {isShowProblems && (
            <div className="problems">
              {problems.map((item, index) => {
                if (item) {
                  console.log(item);
                  return (
                    <div className="problem-item" key={index}>
                      <img src={item.image} alt={item.name} />
                      <strong>{item.name}</strong>
                      <p className="description">{item.text}</p>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </ContentQuestionary>
    </PageModel>
  );
}
