import { PrescriptionType } from "../../types/Prescription";

export default function getHighlights(
  prescription: PrescriptionType,
  shortActivityTime: number,
  interActivityTime: number,
  mobileDeviceTime: number,
  desktopDeviceTime: number,
  fixedDeviceTime: number,
  outdoorActivity: number,
  photophobia: number
) {
  const hightlights = [] as string[];

  //Aberação
  if (Number(prescription.adition) > 3) {
    hightlights.push("Aberração: alta adição na lente");
  }

  //Atividades laborais
  if (shortActivityTime > 4 || interActivityTime > 6) {
    hightlights.push("Muito tempo em atividades laborais");
  }

  //Exposicao a luz azul
  if (mobileDeviceTime + desktopDeviceTime + fixedDeviceTime > 12) {
    hightlights.push("Alta exposição à luz azul");
  } else if (mobileDeviceTime + desktopDeviceTime + fixedDeviceTime > 6) {
    hightlights.push("Exposição à luz azul");
  }

  //Exposicao aos raios UV
  if (outdoorActivity === 2) {
    hightlights.push("Alta Exposição aos raios UV");
  }

  //Fotofobia
  if (photophobia === 2) {
    hightlights.push("Alto grau de fotofobia");
  }

  return hightlights;
}
