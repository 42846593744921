import { FormEvent, useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { ResponseContext } from "../../contexts/responseContext";
import { ResponseCompleteType } from "../../types/Response";
import Loading from "../../components/Loading";
import SearchInput from "../../components/SearchInput";
import { LoginContext } from "../../contexts/loginContext";

import removerCharsCpf from "../../utils/removeCharsCpf";

import "./styles.css";

export default function ConsultantReport() {
  const navigate = useNavigate();

  const { getResponses, responses } = useContext(ResponseContext);

  const { currentUser } = useContext(LoginContext);

  const [filteredResponses, setFilteredResponses] = useState<
    ResponseCompleteType[] | undefined
  >(responses);

  const [searchInput, setSearchInput] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  function capitalize(string: string) {
    return string
      .split(" ")
      .map((x) => x[0].toUpperCase() + x.slice(1))
      .join(" ");
  }

  useEffect(() => {
    if (responses === undefined) {
      getResponses(currentUser?.email).finally(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchInput.trim() === "") {
      setFilteredResponses(responses);
    }
    // eslint-disable-next-line
  }, [searchInput, responses]);

  function searchClient(e: FormEvent) {
    e.preventDefault();

    const value = removerCharsCpf(searchInput.trim());

    setFilteredResponses(
      responses?.filter(
        (item) =>
          item.clientCPF === value ||
          item.client.name.includes(capitalize(searchInput.trim()))
      )
    );
  }

  function openResponse(id: string) {
    navigate(`/consultant/reports/pdf/${id}`);
  }

  return (
    <div className="consultant-report">
      <Header>
        <h1>Relatórios</h1>
      </Header>

      <main>
        {isLoading ? (
          <Loading />
        ) : responses && responses.length > 0 ? (
          <>
            <div className="top-list">
              <span onClick={() => navigate("/")} className="go-back">
                Voltar
              </span>

              <form onSubmit={searchClient}>
                <SearchInput
                  value={searchInput}
                  setValue={(event) => setSearchInput(event.target.value)}
                  placeholder="Nome ou CPF"
                />
              </form>
            </div>
            <div className="list-item-report">
              {filteredResponses &&
                filteredResponses.map((item, index) => {
                  return (
                    <div key={index} onClick={() => openResponse(item.id)}>
                      <strong>Cliente</strong>
                      <p>{item.client.name}</p>
                      <strong>Data</strong>
                      <p>
                        {Intl.DateTimeFormat("pt-br").format(
                          new Date(item.timestamp.seconds * 1000)
                        )}
                      </p>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <h2>Não há questionários feitos por esse consultor.</h2>
            <button
              onClick={() => navigate("/")}
              className="go-back center"
            >
              Voltar
            </button>
          </>
        )}
      </main>
    </div>
  );
}
