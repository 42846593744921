import { PrescriptionType } from "../../types/Prescription";

export default function diopterCalculation(prescription: PrescriptionType) {
  const adition =
    prescription.adition === "-" ? 0 : Number(prescription.adition);

  const esfRight = prescription.rightEye.esf;
  const cilRight =
    prescription.rightEye.cil === "-" ? 0 : prescription.rightEye.cil;
  const esfLeft = prescription.leftEye.esf;
  const cilLeft =
    prescription.leftEye.cil === "-" ? 0 : prescription.leftEye.cil;

  var option1Right = Math.abs(Number(esfRight));
  var option2Right = Math.abs(Number(esfRight) + Number(cilRight));
  var option3Right = Math.abs(Number(esfRight) + adition);
  var option4Right = Math.abs(Number(esfRight) + Number(cilRight) + adition);

  var option1Left = Math.abs(Number(esfLeft));
  var option2Left = Math.abs(Number(esfLeft) + Number(cilLeft));
  var option3Left = Math.abs(Number(esfLeft) + adition);
  var option4Left = Math.abs(Number(esfLeft) + Number(cilLeft) + adition);

  return Math.max(
    option1Right,
    option2Right,
    option3Right,
    option4Right,
    option1Left,
    option2Left,
    option3Left,
    option4Left
  );
}
