import { useContext } from "react";
import { DesignAttributesContext } from "../../contexts/designAttributesContext";
import { DesignType } from "../../types/Categories";
import "./styles.css";

interface MFTableProps {
  designs: DesignType[];
  onSelect: (item: DesignType) => void;
  selected?: DesignType;
}

export default function MFTable({ designs, onSelect, selected }: MFTableProps) {
  const { designAttributes } = useContext(DesignAttributesContext);

  return (
    <div className="mf-table-component">
      {designs.map((item) => (
        <div
          className={`item-table ${selected?.id === item.id && "selected"}`}
          onClick={() => onSelect(item)}
        >
          <div className="logo-box">
            {item.logoURL ? (
              <img src={item.logoURL} alt={item.name} className="logo-column" />
            ) : (
              <p className="logo-column">{item.name}</p>
            )}
          </div>
          <label className="att-column">
            <img src={item.designImageURL} alt={item.name} />
          </label>
          <div className="grid-items">
            {item.attributes?.map((item) => {
              const attribute = designAttributes.find((att) => att.id === item);

              return (
                <label
                  className="att-column mini"
                  title={
                    designAttributes.find((att) => att.id === item)?.description
                  }
                >
                  {attribute?.logoURL ? (
                    <>
                      <div className="box-image">
                        <img
                          src={attribute.logoURL}
                          alt={attribute.description}
                          title={attribute.name}
                        />
                      </div>
                      <p className="description" style={{ fontSize: 15 }}>
                        {attribute?.description}
                      </p>
                    </>
                  ) : (
                    attribute?.name
                  )}
                </label>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
