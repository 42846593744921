import { useContext } from "react"
import { BsFillCaretRightFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { QuestionaryContext } from "../../contexts/questionaryContext"

import "./styles.css"

interface ModalPresentationProps {
  onClose: () => void
}

export default function ModalPresentation({ onClose }: ModalPresentationProps) {
  const navigate = useNavigate()

  const { clearStorage } = useContext(QuestionaryContext)

  function startQuestionary() {
    onClose()

    clearStorage()
  }

  function quitQuestionary() {
    navigate("/")
  }

  return (
    <div className="modal-presentation-component">
      <main>
        <div className="content">
          <img
            src="/assets/logos/MARCA_HORIZONTAL_01.svg"
            alt="My Best Vision"
            className="logo"
          />

          <p className="title">
            O <b>My Best Vision</b> ajudará você a encontrar as melhores
            soluções disponíveis que se adequem a sua rotina.
          </p>

          <div className="actions">
            <button
              className="start-questionary"
              onClick={() => startQuestionary()}
            >
              Iniciar
              <BsFillCaretRightFill size={15} className="button-icon" />
            </button>

            <button
              className="quit-questionary"
              onClick={() => quitQuestionary()}
            >
              Sair
            </button>
          </div>
        </div>
      </main>
    </div>
  )
}
