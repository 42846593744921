import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import { TechnologyType } from "../../../types/Categories";
import PageModel from "../../../components/PageModel";
import "../RefractionPage";
import "../DesignPage";

import ModalVideo from "../../../components/ModalVideo";

import "./styles.css";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function TechnologyPage() {
  const navigate = useNavigate();

  const {
    switchedLens,
    setSwitchedLens,
    getPhotosensitiveOptions,
    saveInStorage,
    glassesSelected
  } = useContext(QuestionaryContext);

  const [recommendation, setRecommendation] = useState<string>("");
  const [extractive, setExtractive] = useState<number>(0);
  const [options, setOptions] = useState<TechnologyType[]>([]);
  const [emptyOption, setEmptyOption] = useState(false);

  const [optionSelectedId, setOptionSelectedId] = useState<string>();
  const [technologyVideo, setTechnlogyVideo] = useState<string>();

  useEffect(() => {

    // Caso contrário, continue com o procedimento padrão
    var data = getPhotosensitiveOptions();

    if (data.options.length === 0) {
      setRecommendation("Não há tecnologias para esse índice dessa lente.");
      setOptionSelectedId("");
      return;
    }

    setRecommendation(data.recommendation);
    setExtractive(data.drive);
    setOptions(data.options);
    setEmptyOption(data.emptyOption);

  }, [glassesSelected, switchedLens, getPhotosensitiveOptions]);

  function switchPhotosensitive(option: string) {
    setOptionSelectedId(option);
  }

  function next() {
    setSwitchedLens({ ...switchedLens, technology: optionSelectedId ?? "" });
    saveInStorage(9);
    navigate("/antireflection");
  }

  function notPhotosensitive() {
    setSwitchedLens({ ...switchedLens, technology: "" });
    saveInStorage(9);
    navigate("/antireflection");
  }

  function previous() {
    saveInStorage(7);
    navigate("/refraction");
  }

  return (
    <PageModel
      title="Tecnologias"
      previous={previous}
      next={optionSelectedId || optionSelectedId === "" ? next : undefined}
      number={7}
    >
      <div className="technology-content">
        {technologyVideo && (
          <ModalVideo
            videoURL={technologyVideo}
            onClose={() => setTechnlogyVideo(undefined)}
          />
        )}

        <p className="recommendation">{recommendation}</p>

        <div className="options">
          {options.map((item, index) => (
            <button
              onClick={() => switchPhotosensitive(item.id)}
              className={
                optionSelectedId === item.id ? "option selected" : "option"
              }
              key={index}
            >
              <div className="box-lens" key={item.name}>
                {item.logo ? (
                  <>
                    <h4>{item.name}</h4><br></br>
                    <img src={item.logo} alt={item.name} />

                  </>
                ) : (
                  <h4>{item.name}</h4>
                )}
              </div>

              <p className="explanation">{item.description}</p>
              {item.videoURL && (
                <button
                  className="info"
                  onClick={() => setTechnlogyVideo(item.videoURL)}
                >
                  <AiOutlineInfoCircle className="icon" />
                </button>
              )}
            </button>
          ))}
        </div>

        {emptyOption && (
          <button onClick={() => notPhotosensitive()} className="option not">
            Não quero
          </button>
        )}

        {extractive > 0 ? (
          <p className="extra-info">
            {`Já que você dirige ou pilota ${extractive === 1 ? "às vezes" : "regularmente"
              }`}
            , temos a indicação de uma tecnologia chamada{" "}
            <strong>Extractive</strong>, que ativa dentro de carros...
          </p>
        ) : null}
      </div>
    </PageModel>
  );
}
