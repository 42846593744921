import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import PageModel from "../../../components/PageModel";
import ModalInformation from "../../../components/ModalInformation";

import questionatyImage from "../../../assets/vision-use-mobile.jpg";
import modalImage from "../../../assets/modal-init-questions.svg";
import "./styles.css";
import RangeInput from "../../../components/RangeInput";
import ContentQuestionary from "../../../components/ContentQuestionary";

//import questionaryImage from "../../../assets/use-of-screens.jpg";

export default function VisonUsePage() {
  const navigate = useNavigate();

  const [isShowingModal, setIsShowingModal] = useState(false);

  useEffect(() => {
    const modalInitQuestionary = sessionStorage.getItem("modal-already-showed");

    if (modalInitQuestionary !== null && modalInitQuestionary === "yes") {
      setIsShowingModal(false);
    } else {
      sessionStorage.setItem("modal-already-showed", "yes");

      setIsShowingModal(true);
    }
  }, []);

  const {
    mobileDeviceTime,
    setMobileDeviceTime,
    desktopDeviceTime,
    setDesktopDeviceTime,
    fixedDeviceTime,
    setFixedDeviceTime,
    shortActivityTime,
    setShortActivityTime,
    interActivityTime,
    setInterActivityTime,
  } = useContext(QuestionaryContext);

  function next() {
    navigate("/questionary/others-info");
  }

  function previous() {
    navigate("/questionary/prescription/resume");
  }

  return (
    <PageModel
      title="Uso da visão nas rotinas diárias"
      previous={previous}
      next={next}
      number={3}
    >
      {isShowingModal && (
        <ModalInformation
          text="Agora queremos saber como são seus hábitos diários"
          label="Vamos lá"
          onClose={() => setIsShowingModal(false)}
        >
          <img src={modalImage} alt="Ilustração" />
        </ModalInformation>
      )}

      <ContentQuestionary srcImage={questionatyImage}>
        <div className="question-teste">
          <h4>
            <strong>Tempo diário em atividades de perto.</strong>
            <br></br>
            [0 - 1 metro] (ler, escrever, costurar, etc);
          </h4>

          <RangeInput
            value={shortActivityTime}
            setValue={setShortActivityTime}
          />

          <h4>
            <strong>
              Tempo diários em atividades em distância intermediária.
            </strong>
            <br></br>
            [1 - 4 metros] (reuniões, cozinhar, limpar)
          </h4>

          <RangeInput
            value={interActivityTime}
            setValue={setInterActivityTime}
          />

          <h4>
            <strong>Tempo diário em dispositivos móveis. </strong>
            <br />
            (celular, tablets, smartwatches, etc.)
          </h4>

          <RangeInput value={mobileDeviceTime} setValue={setMobileDeviceTime} />

          <h4>
            <strong>Tempo diário em dispositivos de mesa.</strong>
            <br />
            (computador, notebooks, etc.)
          </h4>

          <RangeInput
            value={desktopDeviceTime}
            setValue={setDesktopDeviceTime}
          />

          <h4>
            <strong>Tempo diário em dispositivos fixos. </strong>
            <br />
            (assistir séries, filmes, games)
          </h4>

          <RangeInput value={fixedDeviceTime} setValue={setFixedDeviceTime} />
        </div>
      </ContentQuestionary>
    </PageModel>
  );
}
