import { useContext } from "react";
import RangeInput from "../../../components/RangeInput";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import ContentQuestionary from "../../../components/ContentQuestionary";

import questionaryImage from "../../../assets/use-of-screens.jpg";

import "./styles.css";
import OthersInfoPage from "../OthersInfoPage";

export default function UseOfScreensPage() {
  const {
    mobileDeviceTime,
    setMobileDeviceTime,
    desktopDeviceTime,
    setDesktopDeviceTime,
    fixedDeviceTime,
    setFixedDeviceTime,
  } = useContext(QuestionaryContext);

  return (
    <>
      {mobileDeviceTime > 0 && desktopDeviceTime > 0 && fixedDeviceTime > 0 ? (
        <OthersInfoPage />
      ) : (
        <ContentQuestionary srcImage={questionaryImage}>
          <div className="question-box">
            <h4>
              <strong>Tempo diário em dispositivos móveis. </strong>
              <br />
              (celular, tablets, smartwatches, etc.)
            </h4>

            <RangeInput
              value={mobileDeviceTime}
              setValue={setMobileDeviceTime}
            />
          </div>

          <div className="question-box">
            <h4>
              <strong>Tempo diário em dispositivos de mesa.</strong>
              <br />
              (computador, notebooks, etc.)
            </h4>

            <RangeInput
              value={desktopDeviceTime}
              setValue={setDesktopDeviceTime}
            />
          </div>

          <div className="question-box">
            <h4>
              <strong>Tempo diário em dispositivos fixos. </strong>
              <br />
              (assistir séries, filmes, games)
            </h4>

            <RangeInput value={fixedDeviceTime} setValue={setFixedDeviceTime} />
          </div>
        </ContentQuestionary>
      )}
    </>
  );
}
