import FormInput, { FormInputProps } from "../FormInput";

import "./styles.css";

interface FormInputImageProps extends FormInputProps {}

export default function FormInputImage({
  value,
  label,
  ...rest
}: FormInputImageProps) {
  return (
    <div className="form-input-image-component">
      <img src={typeof value === "string" ? value : undefined} alt={label} />

      <FormInput label="" value={value} {...rest} />
    </div>
  );
}
