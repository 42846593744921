import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import { LensContext } from "../../../../contexts/lensContext";

import "./styles.css";
import ListItem from "../../../../components/ListItems";

export default function RegisterRefractionIndex() {
  const navigate = useNavigate();

  const { createRefractionIndex, refractionIndexs } = useContext(LensContext);

  const [name, setName] = useState("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  function onRegister(event: FormEvent) {
    event.preventDefault();

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (refractionIndexs.filter((item) => item.name === name).length > 0) {
      setInputError(["Esse índice de refração já está cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createRefractionIndex(name)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-index-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        <form className="form-register" onSubmit={onRegister}>
          <h2 className="title-form">Cadastrar índice de refração</h2>

          {inputError.length > 0 && <BoxError error={inputError[0]} />}

          {registerSuccess && (
            <BoxSuccess
              message={`Índice de refração ${name} cadastrado com sucesso`}
            />
          )}

          <FormInput
            label="Nome"
            required
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <FormButton children="OK" type="submit" />
        </form>

        <ListItem
          render={refractionIndexs.map((item) => {
            return {
              item: [item.name],
              onEdit: () => {},
            };
          })}
        />
      </main>
    </div>
  );
}
