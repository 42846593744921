import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import FormInput from "../../../../components/FormInput";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import ListItem from "../../../../components/ListItems";
import FormInputImage from "../../../../components/FormInputImage";
import { DesignAttributesContext } from "../../../../contexts/designAttributesContext";
import { DesignAttributeType } from "../../../../types/DesignAttributes";

import "./styles.css";

export default function RegisterDesignAttributes() {
  const navigate = useNavigate();

  const { designAttributes, createAttribute, editAttribute } = useContext(
    DesignAttributesContext
  );

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [logoURL, setLogoURL] = useState("");

  const [inputError, setInputError] = useState<string[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [itemEdit, setItemEdit] = useState<DesignAttributeType>();

  function onRegister(event: FormEvent) {
    event.preventDefault();

    event.currentTarget.scrollTo({ top: 0, behavior: "smooth" });

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    createAttribute(name, description, logoURL)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => setRegisterSuccess(false), 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function onEdit(event: FormEvent) {
    event.preventDefault();

    if (!itemEdit) {
      return;
    }

    event.currentTarget.scrollTo({ top: 0, behavior: "smooth" });

    const id = itemEdit.id;
    const name = itemEdit.name;
    const description = itemEdit.description;
    const logoURL = itemEdit.logoURL;

    if (name.trim() === "") {
      setInputError(["Nome não pode ser vazio"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    if (
      designAttributes.filter((item) => item.name === name && item.id !== id)
        .length > 0
    ) {
      setInputError(["Esse atributo já está cadastrado"]);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    editAttribute(id, name, description, logoURL)
      .then(() => {
        setRegisterSuccess(true);
        setTimeout(() => {
          setItemEdit(undefined);
          setRegisterSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setInputError(["Erro: " + err]);
        setTimeout(() => setInputError([]), 2000);
      });
  }

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-attribute-design-page">
      <Header>
        <button className="logout-button" onClick={() => goBack()} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        {!itemEdit ? (
          <form id="form-edit" className="form-register" onSubmit={onRegister}>
            <h2 className="title-form">Cadastrar atributo de desenho</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Atributo cadastrado com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <FormInput
              label="Descrição"
              required
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

            <FormInputImage
              label="Logo"
              value={logoURL}
              placeholder="Logo desse desenho"
              onChange={(event) => setLogoURL(event.target.value)}
            />

            <FormButton children="OK" type="submit" />
          </form>
        ) : (
          <form id="form-register" className="form-register" onSubmit={onEdit}>
            <h2 className="title-form">Editar atribut de desenho</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}

            {registerSuccess && (
              <BoxSuccess message={`Atributo editado com sucesso`} />
            )}

            <FormInput
              label="Nome"
              required
              value={itemEdit.name}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, name: event.target.value })
              }
            />

            <FormInput
              label="Descrição"
              required
              value={itemEdit.description}
              onChange={(event) =>
                setItemEdit({ ...itemEdit, description: event.target.value })
              }
            />

            <FormInputImage
              label="Logo"
              value={itemEdit.logoURL}
              placeholder="Logo desse desenho"
              onChange={(event) =>
                setItemEdit({ ...itemEdit, logoURL: event.target.value })
              }
            />

            <FormButton children="OK" type="submit" />
            <FormButton
              children="Cancelar"
              type="button"
              onClick={() => setItemEdit(undefined)}
            />
          </form>
        )}

        <ListItem
          render={designAttributes.map((item) => {
            return {
              titles: ["Logo", "Nome", "Descrição"],
              item: [item.logoURL ?? "", item.name, item.description],
              onEdit: () => {
                setItemEdit(item);
              },
            };
          })}
        />
      </main>
    </div>
  );
}
