import "./styles.css";

interface ScoreBarType {
  score?: number;
}

export default function ScoreBar({ score }: ScoreBarType) {
  return (
    <>
      {score ? (
        <>
          {score}
          <div className="range-score">
            {new Array(5).fill(1).map((_, index) => {
              if (index + 1 <= score) {
                return <div className="ball scored" />;
              }
              return <div className="ball"></div>;
            })}
          </div>
        </>
      ) : (
        "-"
      )}
    </>
  );
}
