import { createContext, ReactNode, useEffect, useState } from "react";
import api from "../services/api";
import ConsultantType from "../types/Consultant";
import { deleteDocument, getAllDocuments, getDocumentByParam, updateDocument } from "../controller/firebaseController";

interface ConsultantContextData {
  consultants: ConsultantType[];
  getConsultant: (email: string) => Promise<ConsultantType | undefined>;
  createConsultant: (
    name: string,
    email: string,
    shop: string,
    password: string
  ) => Promise<void>;
  editConsultant: (
    id: string,
    name: string,
    email: string,
    shop: string,
    newPassword?: string
  ) => Promise<unknown>;
  deleteConsultant: (id: string) => Promise<unknown>;
  defineConsultantReport: (consultant: ConsultantType) => void;
  consultantReport: ConsultantType | undefined;
}

interface ConsultantProviderProps {
  children: ReactNode;
}

const colletionName = "consultants"

export const ConsultantContext = createContext({} as ConsultantContextData);

export function ConsultantProvider({ children }: ConsultantProviderProps) {
  const [consultantReport, setConsultantReport] = useState<
    ConsultantType | undefined
  >();

  const [consultants, setConsultants] = useState<ConsultantType[]>([]);

  useEffect(() => {
    getConsultants();
  }, []);

  async function getConsultants() {
    const res = await getAllDocuments(colletionName)

    setConsultants(res.docs.map((item) => {
      const data = item.data() as ConsultantType;
      return { ...data, id: item.id };
    }))
  }

  async function getConsultant(email: string) {
    const response = await getDocumentByParam(colletionName, "email", email)

    if (response.docs.length > 0) {
      const data = response.docs[0].data();
      return { ...data, id: response.docs[0].id } as ConsultantType;
    }

    return undefined;
  }

  async function createConsultant(
    name: string,
    email: string,
    shop: string,
    password: string
  ) {
    try {
      const res = await api.post("/user", { name, email, password });

      if (res.data.id) {
        await updateDocument(colletionName, res.data.id, { name, email, shop })
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function editConsultant(
    id: string,
    name: string,
    email: string,
    shop?: string,
    newPassword?: string
  ) {
    if (newPassword) {
      try {
        await api.put(`/user/${id}`, { name, email, newPassword });
        return await updateDocument(colletionName, id, { name, email, shop: shop ?? "" })
      } catch (err) {
        console.error(err);
        return err;
      }
    } else {
      try {
        await api.put(`/user/${id}`, { name, email });
        return await updateDocument(colletionName, id, { name, email, shop: shop ?? "" })
      } catch (err) {
        console.error(err);
        return err;
      }
    }
  }

  async function deleteConsultant(id: string) {
    try {
      await api.delete(`/user/${id}`);
      return deleteDocument(colletionName, id);
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  function defineConsultantReport(consultant: ConsultantType) {
    setConsultantReport(consultant);
  }

  return (
    <ConsultantContext.Provider
      value={{
        consultantReport,
        consultants,
        getConsultant,
        createConsultant,
        editConsultant,
        defineConsultantReport,
        deleteConsultant,
      }}
    >
      {children}
    </ConsultantContext.Provider>
  );
}
