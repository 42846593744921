import { FormEvent, useContext, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import FormButton from "../../../../components/FormButton";
import Header from "../../../../components/Header";
import BoxError from "../../../../components/BoxError";
import BoxSuccess from "../../../../components/BoxSuccess";
import { LensContext } from "../../../../contexts/lensContext";
import * as XLSX from 'xlsx';
import "./styles.css";

interface LensData {
  Codigo: string;
  Preco: number;
}

export default function AtualizarPrices() {
  const navigate = useNavigate();
  const { lenses, editLens } = useContext(LensContext);
  const [file, setFile] = useState<File | null>(null);
  const [inputError, setInputError] = useState<string[]>([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updatedLenses, setUpdatedLenses] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      console.log("Arquivo selecionado:", event.target.files[0].name);
    }
  };

  const handleFileUpload = async (event: FormEvent) => {
    event.preventDefault();

    if (!file) {
      setInputError(['Por favor, selecione um arquivo para upload!']);
      setTimeout(() => setInputError([]), 2000);
      return;
    }

    setLoading(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target?.result;
      if (data) {
        try {
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData: LensData[] = XLSX.utils.sheet_to_json(sheet);

          const updatedLensCodes: string[] = [];

          for (const row of jsonData) {
            const { Codigo, Preco } = row;
            const lens = lenses.find((item) => item.cod === Codigo);

            if (lens) {
              try {
                await editLens({
                  ...lens,
                  price: Number(Preco),
                });
                updatedLensCodes.push(Codigo);
                console.log(`Lente atualizada - Código: ${Codigo}, Preço: ${Preco}`);
              } catch (err) {
                console.error(`Erro ao atualizar a lente com código ${Codigo}:`, err);
              }
            } else {
              setInputError((prev) => [...prev, `Lente com código ${Codigo} não encontrada.`]);
            }
          }

         
          setUpdatedLenses((prevLenses) => [...prevLenses, ...updatedLensCodes]);

        
          setUpdateSuccess(true);
          setTimeout(() => setUpdateSuccess(false), 2000);
        } catch (error) {
          console.error("Erro ao processar o arquivo:", error);
          setInputError(['Erro ao processar o arquivo.']);
          setTimeout(() => setInputError([]), 2000);
        } finally {
          setLoading(false);
        }
      }
    };


    reader.onerror = () => {
      setInputError(['Erro ao ler o arquivo.']);
      setLoading(false);
    };

    reader.readAsBinaryString(file);

  
    return false;
  };

  function goBack() {
    navigate("/admin");
  }

  return (
    <div className="register-family-page">
      <Header>
        <button className="logout-button" onClick={goBack} title="Sair">
          <BsArrowLeftShort className="icon" size={30} />
        </button>
      </Header>

      <main>
        <div className="update-lenses-page">
          <form className="form-update" onSubmit={handleFileUpload}>
            <h2 className="title-form">Atualizar Preços das Lentes</h2>

            {inputError.length > 0 && <BoxError error={inputError[0]} />}
            {updateSuccess && <BoxSuccess message="Preços atualizados com sucesso" />}

            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            <FormButton type="submit">Atualizar</FormButton>
          </form>
        </div>

        <div className="updated-lenses">
          <h3>Lentes Atualizadas:</h3>
          {updatedLenses.length > 0 ? (
            <ul>
              {updatedLenses.map((Codigo) => (
                <li key={Codigo}>{Codigo}</li>
              ))}
            </ul>
          ) : (
            <p>Nenhuma lente atualizada ainda.</p>
          )}
        </div>

       

      </main>
    </div>
  );
}
