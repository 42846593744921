import { useEffect, useState } from "react";

import './styles.css';

interface MonitorItemProps {
  items: any[] | undefined;
}

export default function MonitorItem({items}: MonitorItemProps) {
  const [totalClients, setTotalClients] = useState(0);

  useEffect(() => {
    let count = 0;
    items?.map((item) => {
      return count += item.dataReports.length;
    })
    setTotalClients(count);
  }, [items])
  
  return (
    <div className="MonitorItem">
      {/* <div className="consultants">
        {items?.map((item) => <div>{item.consultant.name}</div>)}
      </div> */}
      <button onClick={() => console.log(items)}>Teste metri</button>
      <div className="metricas">
        <h2>Métricas</h2>
        <div className="consultants">
          <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
          <h4>Consultores: </h4>
          {
            items?.sort((a, b)=> a.dataReports?.length - b.dataReports?.length).reverse().map((item) => {
              return <div className="list">
                <p>Consultor: {item.consultant.name}</p>
                <p>Clientes registrados: {item.dataReports.length}</p>
              </div>;
            }
            )
            }
          </div>
        </div>
        <h4>Total de clientes registrados: {totalClients} </h4>
      </div>
    </div>
  );
}