import { Link } from "react-router-dom";

import notFoundImage from "../../assets/not-found.svg";

import "./styles.css";

export default function PageNotFound() {
  return (
    <div className="page-not-found">
      <h1>Página não encontrada</h1>
      <img
        src={notFoundImage}
        alt="Ilustração de um homem segurando uma caixa vazia"
      />
      <Link to="/" children="Ir para página inicial" className="to-initial" />
    </div>
  );
}
