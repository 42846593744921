import { PrescriptionType } from "../../types/Prescription";

export default function visionProblems(prescription: PrescriptionType) {
  let maxEsf: number;

  if (
    Math.abs(Number(prescription.leftEye.esf)) >
    Math.abs(Number(prescription.rightEye.esf))
  ) {
    maxEsf = Number(prescription.leftEye.esf);
  } else {
    maxEsf = Number(prescription.rightEye.esf);
  }

  const maxCil = Math.min(
    Number(prescription.leftEye.cil),
    Number(prescription.rightEye.cil)
  );

  const problems = [];

  if (maxEsf > 0) {
    problems.push("Hipermetropia");
  } else if (maxEsf < 0) {
    problems.push("Miopia");
  } else if (maxCil === 0 && prescription.adition === "") {
    problems.push("Emetropia");
  }

  if (maxCil !== 0 && !isNaN(maxCil)) {
    problems.push("Astigmatismo");
  }

  if (prescription.adition !== "") {
    problems.push("Presbiopia");
  }

  return problems;
}
