import { ClientProvider } from "./contexts/clientContext";
import { ConsultantProvider } from "./contexts/consultantContext";
import { DesignAttributesProvider } from "./contexts/designAttributesContext";
import { LensProvider } from "./contexts/lensContext";
import { LoginProvider } from "./contexts/loginContext";
import { QuestionaryProvider } from "./contexts/questionaryContext";
import { ResponseProvider } from "./contexts/responseContext";
import { ShopProvider } from "./contexts/shopContext";
import Routes from "./routes";

import "./index.css";

function App() {
  return (
    <ConsultantProvider>
      <LoginProvider>
        <ClientProvider>
          <LensProvider>
            <DesignAttributesProvider>
              <ShopProvider>
                <ResponseProvider>
                  <QuestionaryProvider>
                    <Routes />
                  </QuestionaryProvider>
                </ResponseProvider>
              </ShopProvider>
            </DesignAttributesProvider>
          </LensProvider>
        </ClientProvider>
      </LoginProvider>
    </ConsultantProvider>
  );
}

export default App;
