import { useContext, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router";
import { ClientContext } from "../../contexts/clientContext";
import ButtonQuitQuestionary from "../ButtonQuitQuestionary";
import ModalQuitQuestionary from "../ModalQuitQuestionary";

export default function PrivateRouteQuestionary() {
  const navigate = useNavigate();

  const { client } = useContext(ClientContext);

  const [wantQuitQuestionary, setWantQuitQuestionary] = useState(false);

  function onQuitQuestionary() {
    navigate("/");
  }

  return (
    <>
      {client ? (
        <>
          <Outlet />
          {wantQuitQuestionary && (
            <ModalQuitQuestionary
              onQuit={onQuitQuestionary}
              onCancel={() => setWantQuitQuestionary(false)}
            />
          )}
          <ButtonQuitQuestionary
            onQuit={() => setWantQuitQuestionary(true)}
          />
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  )
}
