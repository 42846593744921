import { createContext, ReactNode, useContext } from "react";
import { PrescriptionType } from "../types/Prescription";
import ResponseType, { ResponseCompleteType } from "../types/Response";
import { ClientContext } from "./clientContext";
import { ConsultantContext } from "./consultantContext";
import { LensContext } from "./lensContext";
import { useState } from "react";
import ConsultantType from "../types/Consultant";
import { addDocument, getAllDocuments, getDocumentById, getDocumentByParam } from "../controller/firebaseController";
import Consultant from "../types/Consultant";

interface ResponseContextData {
  responses: ResponseCompleteType[] | undefined;
  metrics: unknown[] | undefined;
  getResponse: (id: string) => Promise<ResponseCompleteType | undefined>;
  getResponses: (email?: string) => Promise<void>;
  getMetrics: (shop: string) => Promise<void>;
  createResponse: (
    clientCPF: string,
    consultantID: string,
    highlights: string[],
    lensId: string,
    frames: string,
    contactLens: string,
    secondPair: string,
    prescription: PrescriptionType
  ) => Promise<string>;
  cleanResponses: () => void;
}

interface ResponseProviderProps {
  children: ReactNode;
}

export const ResponseContext = createContext({} as ResponseContextData);

export function ResponseProvider({ children }: ResponseProviderProps) {
  const { getClient } = useContext(ClientContext);
  const { getConsultant } = useContext(ConsultantContext);
  const { lenses } = useContext(LensContext);

  const [responses, setResponses] = useState<ResponseCompleteType[]>();
  const [metrics, setMetrics] = useState<{consultant: Consultant | undefined, dataReports: ResponseType[]}[]>([]);

  async function getResponse(id: string) {
    const response = await getDocumentById("responses", id);
    const data = response.data() as ResponseType;

    if (data) {
      const client = await getClient(data.clientCPF);
      const consultant = await getConsultant(data.consultantEmail);
      const lens = lenses.find(
        (item) => data && item.id === data.lensId
      );

      const completeResponse = {
        ...data,
        client,
        consultant,
        lens,
      } as ResponseCompleteType;

      return completeResponse;
    }

    return undefined;
  }

  async function getResponses(email?: string) {
    let response;

    if (email) {
      response = await getDocumentByParam("responses", "consultantEmail", email, "timestamp");
    } else {
      response = await getAllDocuments("responses", "timestamp");
    }

    const data = response.docs.map((item) => {
      return { ...item.data(), id: item.id } as ResponseType;
    });

    const responses = [] as ResponseCompleteType[];

    for (let i = 0; i < data.length; i++) {
      const response = data[i];

      const client = await getClient(response.clientCPF);

      const consultant = await getConsultant(response.consultantEmail);

      const lens = lenses.find((item) => item.id === response.lensId);

      responses.push({
        ...response,
        client,
        consultant,
        lens,
      } as ResponseCompleteType);
    }

    setResponses(
      responses.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
    );

  }

  async function getMetrics(shop: string) {
    const metricsResponse = [];
    const response = await getDocumentByParam("consultants", "shop", shop)

    const dataConsultant = response.docs.map((item) => {
      return { ...item.data(), id: item.id } as ConsultantType;
    });

    for (let i = 0; i < dataConsultant.length; i++) {
      const consultant = await getConsultant(dataConsultant[i].email)
      const responseReports = await getDocumentByParam("consultants", "consultantEmail", dataConsultant[i].email);

      const dataReports = responseReports.docs.map((item) => {
        return { ...item.data(), id: item.id } as ResponseType;
      });

      metricsResponse.push({
        consultant: consultant,
        dataReports,
      });
    }
    setMetrics(metricsResponse);
  }

  async function createResponse(
    clientCPF: string,
    consultantEmail: string,
    highlights: string[],
    lensId: string,
    frames: string,
    contactLens: string,
    secondPair: string,
    prescription: PrescriptionType
  ) {
    const response = await addDocument("responses", {
      clientCPF,
      consultantEmail,
      highlights,
      lensId,
      contactLens,
      secondPair,
      prescription,
      frames,
      timestamp: new Date(),
    })

    cleanResponses();

    return response.id;
  }

  function cleanResponses() {
    setResponses(undefined);
  }

  return (
    <ResponseContext.Provider
      value={{
        responses,
        metrics,
        getResponse,
        getResponses,
        createResponse,
        getMetrics,
        cleanResponses,
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
}
