import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionaryContext } from "../../../contexts/questionaryContext";
import PageModel from "../../../components/PageModel";

import sunGlassesImage from "../../../assets/sunglasses.jpg";
import interDistanceImage from "../../../assets/inter-distance.jpg";
import shortDistanceImage from "../../../assets/short-distance.jpg";
import contactLensImage from "../../../assets/contact-lens.jpg";

import "./styles.css";

export default function SecondPairPage() {
  const navigate = useNavigate();

  const {
    getAntireflectionOptions,
    getPhotosensitiveOptions,
    setSwitchedSecondPair,
    setSwitchedContactLens,
    getIndicateSecondPair,
    //getIndicateContactLenses,
    saveInStorage,
  } = useContext(QuestionaryContext);

  const [useRecommendation, setUseRecommendation] = useState("");
  const [recommendation, setRecommendation] = useState<number>(2);
  const [selected, setSelected] = useState<number[]>([]);
  const [switchContactL, setSwitchContactL] = useState("nao");

  useEffect(() => {
    const indication = getIndicateSecondPair();
    //const indicationCL = getIndicateContactLenses();

    setUseRecommendation(indication.use);
    setRecommendation(indication.recommendation);
    // eslint-disable-next-line
  }, [navigate]);

  function previous() {
    if (getAntireflectionOptions().options.length === 0) {
      if (getPhotosensitiveOptions().options.length === 0) {
        navigate("/refraction");
        saveInStorage(7);
        return;
      }
      saveInStorage(8);
      navigate("/photosensitive");
      return;
    }

    saveInStorage(9);
    navigate("/antireflection");
  }

  // function next() {
  //   //Redireciona para pagina final, se for indicado lente de contato
  //   var contactLens = "";
  //   saveInStorage(11);

  //   setSwitchedContactLens(contactLens);
  // }

  //   if (!indicationCL) {
  //     saveInStorage(12);
  //     contactLens = "Não";
  //     navigate("/congratulations");
  //   } else navigate("/congratulations");

  function next() {
    setSwitchedContactLens(switchContactL);
    setSwitchedSecondPair(
      selected.map((item) => {
        if (item === 0) {
          return "Lentes para perto";
        } else if (item === 1) {
          return "Lentes ocupacionais";
        }
        return "Óculos solar";
      }) as string[]
    );
    navigate("/congratulations");
  }
  console.log(switchContactL);
  return (
    <PageModel
      title="Segundo par e lentes de contato"
      previous={previous}
      next={next}
      number={9}
    >
      <div className="second-pair-content">
        <p className="recommendation">Uso {useRecommendation}</p>

        <div className="options">
          {(recommendation === 0 || recommendation === 1) && (
            <>
              <div
                className={`${
                  selected.includes(0) ? "option selected" : "option"
                }`}
                onClick={() =>
                  selected.includes(0)
                    ? setSelected(selected.filter((item) => item !== 0))
                    : setSelected(selected.concat(0))
                }
              >
                <h3>Lentes para perto</h3>
                <img src={shortDistanceImage} alt="Lentes para perto" />
                <p className="description">
                  Ideais para quem passa muito tempo trabalhando com a visão
                  exclusivamente de perto, as lentes para perto dão amplo campo
                  de visão evitando a “procura” do foco.
                </p>
              </div>

              <div
                className={`${
                  selected.includes(1) ? "option selected" : "option"
                }`}
                onClick={() =>
                  selected.includes(1)
                    ? setSelected(selected.filter((item) => item !== 1))
                    : setSelected(selected.concat(1))
                }
              >
                <h3>Lentes ocupacionais</h3>
                <img src={interDistanceImage} alt="Lentes ocupacionais" />
                <p className="description">
                  Ideias para quem passa várias horas do dia trabalhando no
                  computador ou qualquer outra atividade que utilize
                  prioritariamente a visão intermediária e perto. Para ver com
                  nitidez a diferentes distâncias, as pessoas adotam,
                  inconscientemente, uma postura desconfortável, como, por
                  exemplo, inclinando-se para frente ou levantando a cabeça. Dor
                  nas costas e no pescoço, olhos secos ou lacrimejantes são
                  apenas algumas das possíveis consequências. As lentes
                  ocupacionais ajudam a evitar esse tipo de estresse visual e
                  aumentam a sua produtividade.
                </p>
              </div>
            </>
          )}

          <div
            className={`${selected.includes(2) ? "option selected" : "option"}`}
            onClick={() =>
              selected.includes(2)
                ? setSelected(selected.filter((item) => item !== 2))
                : setSelected(selected.concat(2))
            }
          >
            <h3>Óculos solar</h3>
            <img src={sunGlassesImage} alt="Óculos solar" />
            <p className="description">
              <ul className="topics">
                <li>
                  Proteção contra radiação UV, inclusive em dias nublados;
                </li>
                <li>Diminui fotofobia;</li>
                <li>
                  Evita envelhecimento precoce da região dos olhos e testa;
                </li>
                <li>
                  Melhora a percepção das cores quando o ambiente está muito
                  iluminado;
                </li>
                <li>Maior conforto em ambientes claros;</li>
                <li>Diminuir risco de catarata e outras doenças oculares;</li>
              </ul>
            </p>
          </div>

          <div
            className={`${
              switchContactL.includes("sim") ? "option selected" : "option"
            }`}
            onClick={() =>
              switchContactL.includes("sim")
                ? setSwitchContactL("nao")
                : setSwitchContactL("sim")
            }
          >
            <h3>Você sabia?</h3>
            <img src={contactLensImage} alt="Lentes de contato" />
            <p className="description">
              Apesar de não substituiresm os óculos, as lentes de contato são
              uma excelente opção para quem quer ir a algum evento e não pode ou
              não quer utilizar seus óculos como por exemplo em festas de
              casamento, formaturas ou mesmo praticar algum esporte em os óculos
              atrapalham ou não podem ser incluído o grau.
            </p>
          </div>
        </div>
      </div>
    </PageModel>
  );
}
