import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { auth } from "../services/firebase"
import { ConsultantContext } from "./consultantContext"
import ConsultantType from "../types/Consultant"
import Loading from "../components/Loading"
import { signInWithEmailAndPassword } from "firebase/auth"
import { FirebaseError } from "firebase/app"

interface LoginContextData {
  login: (
    email: string,
    password: string
  ) => Promise<{ ok: boolean; error?: FirebaseError }>
  logout: () => Promise<void>
  currentUser?: ConsultantType
}

interface LoginProviderProps {
  children: ReactNode
}

export const LoginContext = createContext({} as LoginContextData)

export function LoginProvider({ children }: LoginProviderProps) {
  const [currentUser, setCurrentUser] = useState<ConsultantType>()
  const [isLoading, setIsLoading] = useState(true)

  const [param, setParam] = useState(0)

  const { getConsultant, consultants } = useContext(ConsultantContext)

  useEffect(() => {
    if (param === 0) {
      setParam(1)
      return
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [currentUser])

  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser && currentUser.email) {
        getConsultant(currentUser.email).then((user) => {
          setCurrentUser(user)
          setIsLoading(false)
        })
      } else {
        setIsLoading(false)
      }
    })
    // eslint-disable-next-line
  }, [])

  async function login(email: string, password: string): Promise<{ ok: boolean; error?: FirebaseError }> {
    setIsLoading(true)

    try {
      const userCredentials = await signInWithEmailAndPassword(auth, email, password)

      if (userCredentials.user?.email) {
        // const consultant = await getConsultant(res.user.email)
        const consultant = consultants.find(
          (item) => item.email === userCredentials.user?.email
        )

        setCurrentUser(consultant)

        if (!consultant) {
          return { ok: false, error: { code: "Consultant not found" } as FirebaseError }
        }
      } else {
        setCurrentUser(undefined)
      }

      return { ok: true }
    } catch (err) {
      setCurrentUser(undefined)
      const error = err as FirebaseError

      return { ok: false, error }
    } finally {
      setIsLoading(false)
    }

  }

  function logout() {
    return auth.signOut()
  }

  return (
    <LoginContext.Provider value={{ login, logout, currentUser }}>
      {isLoading ? <Loading /> : children}
    </LoginContext.Provider>
  )
}
