export default function removerCharsCpf(cpf: string) {
    const stringArray = cpf.trim().split("");

    //Retira todos os caracteres que nao forem numeros
    var cpfFormated = stringArray.map(char => {
        for(let i = 0; i < 10; i++) {
            if(char === `${i}`) {
                return char;
            }
        }
        return "";
    }).join("");

    return cpfFormated;
}