import { IoIosArrowUp } from "react-icons/io";

import "./styles.css";

interface ButtonQuitQuestionaryProps {
  onQuit: () => void;
}

export default function ButtonQuitQuestionary({
  onQuit,
}: ButtonQuitQuestionaryProps) {
  return (
    <button
      className="button-quit-questionary-component"
      onClick={() => onQuit()}
    >
      <p>Sair</p>
      <IoIosArrowUp className="icon" />
    </button>
  );
}
