import { useEffect, useState } from "react";
import { AiOutlineSortAscending } from "react-icons/ai";
import { BiAlignJustify, BiDetail } from "react-icons/bi";
import { BsTextLeft } from "react-icons/bs";
import { MdCreate, MdDeleteForever, MdOndemandVideo } from "react-icons/md";
import Lens from "../../types/Lens";
import ListItemsOptionType from "../../types/ListItemsOption";
import listItemsFilter from "../../utils/listItemsFilter";
import FormButton from "../FormButton";
import FormInput from "../FormInput";

import "./styles.css";

const dateRegex =
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

interface ListItemProps extends React.HTMLAttributes<any> {
  titles?: string[];
  render: ListItemsOptionType[];
  relatory?: Lens[];
}

export default function ListItem({ render, titles, relatory }: ListItemProps) {
  const [sortingParam, setSortingParam] = useState(0);
  const [sortingDesc, setSortingDesc] = useState<boolean>(true);

  const [filters, setFilters] = useState<string[]>([]);
  const [tempFilters, setTempFilters] = useState<string[]>([]);

  useEffect(() => {
    const newFilters = [] as string[];

    titles?.map((_) => newFilters.push(""));

    setTempFilters(newFilters);
  }, [titles]);

  function changeSort(param: number) {
    setSortingDesc(param === sortingParam ? !sortingDesc : true);
    setSortingParam(param);
  }

  function sortingItems(arrayA: string[], arrayB: string[]) {
    var itemA = arrayA[sortingParam].toLowerCase();
    var itemB = arrayB[sortingParam].toLowerCase();

    if (itemA.includes("r$")) {
      itemA = itemA.replace("r$", "").replace(".", "").replace(",", ".").trim();
      itemB = itemB.replace("r$", "").replace(".", "").replace(",", ".").trim();
    }

    if (dateRegex.test(itemA)) {
      console.log("match");

      itemA = itemA.split("/").reverse().join("-");
      itemB = itemB.split("/").reverse().join("-");
    }

    var value = 0;

    if (Number(itemA) && Number(itemB)) {
      try {
        const numberA = Number(itemA);
        const numberB = Number(itemB);

        if (sortingDesc) {
          if (numberA > numberB) value = 1;
          else value = -1;
        } else {
          if (numberA > numberB) value = -1;
          else value = 1;
        }
      } catch {
        value = 0;
      }
    } else {
      if (sortingDesc) {
        if (itemA > itemB) value = 1;
        else value = -1;
      } else {
        if (itemA > itemB) value = -1;
        else value = 1;
      }
    }

    return value;
  }

  return (
    <div className="list-component" key={render.toString()}>
      {titles && (
        <div className="filters-box">
          {titles.map((item, index) => (
            <FormInput
              key={index}
              label={item}
              value={tempFilters[index] ?? ""}
              onChange={(event) =>
                setTempFilters(
                  tempFilters.map((filter, i) =>
                    index === i ? event.target.value : filter
                  )
                )
              }
            />
          ))}

          <FormButton onClick={() => setFilters(tempFilters)}>
            Filtrar
          </FormButton>
        </div>
      )}

      <header>
        {titles &&
          titles.map((item, index) => (
            <button
              key={index}
              className="list-titles"
              onClick={() => changeSort(index)}
            >
              {item}
              <AiOutlineSortAscending size={20} />
            </button>
          ))}
        {!titles &&
          render[0] &&
          render[0].item.map((_, index) => {
            return (
              <button
                className="button-sort"
                key={index}
                onClick={() => changeSort(index)}
              >
                <AiOutlineSortAscending size={20} />
              </button>
            );
          })}
      </header>

      {render.length > 0 &&
        render
          .sort((a, b) => sortingItems(a.item, b.item))
          .filter((option) => listItemsFilter(option, filters))
          .map((option, index) => (
            <article key={index}>
              {option.item.map((part, i) => {
                if (part && part.includes("https://")) {
                  return (
                    <img key={i} src={part} alt={part} className="attribute" />
                  );
                }

                return (
                  <label title={part} key={i} className="attribute">
                    {part}
                  </label>
                );
              })}

              {option.hasDescription && <BsTextLeft className="icon-desc" />}

              {option.hasVideo && <MdOndemandVideo className="icon-desc" />}

              <div>
                {option.onDetails && (
                  <BiDetail
                    className="actions"
                    size={20}
                    onClick={() => option.onDetails && option.onDetails()}
                    style={{ color: "#00246e" }}
                  />
                )}
                {option.onEdit && (
                  <MdCreate
                    className="actions"
                    size={20}
                    onClick={() => option.onEdit && option.onEdit()}
                    style={{ color: "#774420" }}
                  />
                )}
                {option.onSelect && (
                  <BiAlignJustify
                    className="actions"
                    size={20}
                    onClick={() => option.onSelect && option.onSelect()}
                    style={{ color: "#774420" }}
                  />
                )}
                {option.onDelete && (
                  <MdDeleteForever
                    className="actions"
                    size={20}
                    onClick={() => option.onDelete && option.onDelete()}
                    style={{ color: "#880000" }}
                  />
                )}
              </div>
            </article>
          ))}

      {render.length === 0 && <h3>Não há itens cadastrados</h3>}
    </div>
  );
}
